import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../api/current-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'up-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public user;

  constructor(private currentUser: CurrentUserService) {
    this.user = this.currentUser.getUser();
  }

  public logout() {
    this.currentUser.logout();
  }
}

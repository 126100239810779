import { Component } from '@angular/core';

@Component({
  selector: 'up-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

}

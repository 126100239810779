import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersModule } from '../users/users.module';
import { UsersComponent } from '../users/users.component';
import { ReviewCallsComponent } from './review-calls.component';
import { MemberLookupComponent } from './member-lookup/member-lookup.component';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { CompanySelectComponent } from './company-select/company-select.component';
import { AddMemberDialogComponent } from './add-member-dialog/add-member-dialog.component';
const routes: Routes = [
  {
    path: '',
    children: [{
      path: '',
      data: {
        title: 'Review Calls'
      },
      component: ReviewCallsComponent
    }, {
      path: 'companies/:companyId',
      component: UsersComponent,
      data: {title: 'test'}
    }]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    UsersModule,
    NguiAutoCompleteModule
  ],
  declarations: [ReviewCallsComponent, MemberLookupComponent, CompanySelectComponent, AddMemberDialogComponent],
  entryComponents: [AddMemberDialogComponent]
})
export class ReviewCallsModule { }

import { CurrentUserService } from '../api/current-user.service';

export function loadUser(currentUserService: CurrentUserService): () => Promise<any> {
  return () => {
    return new Promise(async resolve => {
      try {
        await currentUserService.loadUser().toPromise();
        // User is authenticated
        resolve();
        return;
      } catch (e) {
        currentUserService.logout();
      }
    });
  };
}

import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toast } from '../shared/toast-message/toast';
import { RateHistoryItem } from '../types/reward';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { UsersService } from '../api/users.service';
import { Event, EventsService } from '../api/events.service';
import * as _ from 'lodash-es';

declare let $: any;

@Component({
  selector: 'up-rewards-log',
  templateUrl: './rewards-log.component.html',
  styleUrls: ['./rewards-log.component.scss']
})
export class RewardsLogComponent extends BaseComponent implements OnInit {
  public loading = false;
  events: Event[] = [];
  selectedTabEvents: Event[] = [];
  public toast: Toast;
  public header: any[] = [{ key: 'createdAt', title: 'Date' }, { key: 'event', title: 'Event' }];
  public searchString = '';
  keys = [
    'teamMemberIndividualRewardReceived',
    'memberRedeemedRewardForCard',
    'rewardCardOrder',
    'companies',
    'rewards',
    'expiredRewardPeriod'
  ];
  tabs = ['all', ...this.keys];
  selectedTab = 'all';
  dateFormat = 'MM-dd-y h:mm a';

  constructor(
    private usersService: UsersService,
    private modalService: NgbModal,
    private eventsService: EventsService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.subs = this.eventsService
      .get(0, 10000)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(events => {
        this.events = events;
        this.selectedTabEvents = this.filterByTab();
      });
  }

  filterByTab() {
    if (this.selectedTab === 'all') {
      return this.events;
    }
    return this.events.filter(event => event.data[this.selectedTab]);
  }

  toTable(data: any) {
    if (typeof data === 'string') {
      return [['message', data]];
    }
    if (typeof data !== 'object') {
      console.warn('Invalid log type: ', typeof data);
      return [];
    }
    return Object.entries(data).filter(entry => {
      return entry[0] !== 'companyId';
    });
  }

  isDateColumn(column: string) {
    return ['targetStartDate', 'targetExpirationDate', 'date'].includes(column);
  }

  shouldSkipKey(text: string): boolean {
    const number = parseInt(text, 10);
    const isUuid = text.length === 36;
    return isUuid || (number >= 0 || number < 100);
  }

  isObject(unknown: unknown) {
    return _.isObject(unknown);
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
    this.selectedTabEvents = this.filterByTab();
  }
}

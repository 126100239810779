import { FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';

export class ValidationService {

  static getValidatorErrorMessage(validatorName: string, messages?: any): string {
    const config = {
      required: 'This field is required.',
      whitespace: 'Whitespace is not allowed.',
      email: 'Please enter a valid email address.',
      equalTo: 'Please enter the same value again.',
      password: 'Please enter at least 8 characters.',
      maxlength: 'This field should contain maximum 2 characters.',
      minlength: 'This field should contain minimum 5 characters.',
      emaislList: 'Please enter valid email addresses.'
    };

    return messages[validatorName] || config[validatorName];
  }

  static password(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value.match(/^(.){8,}$/)) {
      return { password: true };
    }
  }

  public noWhiteSpace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
   }

  public markAllControlsAsTouched(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      }
    });
  }

  public emaislList(control: FormControl) {
    let isValid = true
    if (control.value !== null) {
      const values = control.value.split(',')

      isValid = values.reduce((acc, current) => {
        const valueTested = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(current.trim())
        acc = valueTested
        return acc
      }, true)
    }

    return isValid ? null : {'emaislList': true}
  }

  public invoicePastDueValidation(control: AbstractControl) {
    if (control && control.get("invoiceDueDays") && control.get("invoiceExpireDays")) {
      const expired = parseInt(control.get("invoiceExpireDays").value)
      const pastDue = parseInt(control.get("invoiceDueDays").value)

      return (pastDue > expired) ? { 'datesError': true } : null
    }
    return null;
  }
}

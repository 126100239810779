import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../shared/validators/validators.service';
import {CurrentUserService} from '../api/current-user.service';
import { Toast } from '../shared/toast-message/toast';
import { BaseComponent } from '../shared/base-component/base-component.component';

@Component({
  selector: 'up-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public submitted: boolean;
  public toast: Toast;
  public loading: boolean;
  public oldPasswordMessage: any = { passwordMismatch: 'Current password is invalid.' };

  constructor(private formBuilder: FormBuilder,
              private validator: ValidationService,
              private currentUser: CurrentUserService) {
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      new_password: ['', [Validators.required, ValidationService.password]],
      new_password_confirm: ['', [Validators.required]],
      old_password: ['', [Validators.required, ValidationService.password]]
    });
  }

  confirm(): void {
    this.submitted = true;
    const oldPassword = this.form.get('old_password');
    const newPassword = this.form.get('new_password');
    const newPasswordConfirm = this.form.get('new_password_confirm');
    if (newPassword.value !== newPasswordConfirm.value) {
      newPasswordConfirm.setErrors({ equalTo: true });
    }
    if (this.form.invalid) {
      this.validator.markAllControlsAsTouched(this.form);
      return;
    }

    this.loading = true;
    this.subs = this.currentUser
      .updatePassword({old_password: oldPassword.value, new_password: newPassword.value})
      .finally(() => this.loading = false)
      .subscribe(
        () => {
          this.toast = {
            message: 'Password was updated successfully.',
            type: 'success'
          };
        },
        (res) => {
          let msg: string;
          if ( res.error.error.includes('old password') ) {
            msg = 'Current password is incorrect.';
          } else {
            msg = 'Cannot change password. Please try again.';
          }
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });

  }

}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ValidationService } from '../../shared/validators/validators.service';
import { Toast } from '../../shared/toast-message/toast';

@Component({
  templateUrl: './add-member-dialog.component.html',
  styleUrls: ['./add-member-dialog.component.scss']
})
export class AddMemberDialogComponent {

  public toast: Toast;
  public loading: boolean;
  public memberForm: FormGroup;
  public isFormSubmitted: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    public activeModal: NgbActiveModal
  ) {
    this.createForm();
  }

  public submitMemberForm(): void {
    if (!this.memberForm.valid) {
      this.isFormSubmitted = true;
      this.validationService.markAllControlsAsTouched(this.memberForm);
      return;
    }
    this.activeModal.close(this.memberForm.value);
  }

  private createForm() {
    this.memberForm = this.formBuilder.group({
      first_name: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]]
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { TableHeaderItem } from '../shared/table-base/table-header-item.interface';
import { UsersService } from '../api/users.service';
import { SupportManagersService } from '../api/supportManagers.service';
import { Toast } from '../shared/toast-message/toast';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { User } from '../types/user';

declare const $: any;

@Component({
  templateUrl: './support-managers.component.html',
  styleUrls: ['./support-managers.component.scss']
})
export class SupportManagersComponent extends BaseComponent implements OnInit {
  public users: User[];
  public searchString: string;
  public selectedUser: User;
  public toast: Toast;
  public header: TableHeaderItem[] = [
    {
      key: 'first_name',
      title: 'First name'
    },
    {
      key: 'last_name',
      title: 'Last name'
    },
    {
      key: 'email',
      title: 'Email'
    },
    {
      key: 'actions',
      title: 'Actions'
    }
  ];

  constructor(
    private usersService: UsersService,
    private supportManagersService: SupportManagersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs = this.supportManagersService
      .getAll()
      .subscribe(users => this.users = users);
  }


  public editUser(user: User): void {
    this.selectedUser = { ...user };
    $('#user-dialog').modal('show');
  }

  public sendWelcomeEmail(user: User): void {
    this.selectedUser = user;
    $('#send-email-dialog').modal('show');
  }

  public sendEmail(): void {
    this.subs = this.usersService
      .sendEmail(this.selectedUser.id)
      .subscribe(
        () => {
          this.toast = {
            message: 'Email was sent.',
            type: 'success'
          };
        },
        () => {
          this.toast = {
            message: 'Cannot send email. Please try again.',
            type: 'danger'
          };
        });
  }

  public showDeleteUserDialog(user: User): void {
    this.selectedUser = user;
    $('#delete-user-dialog').modal('show');
  }

  public deleteUser(): void {
    this.subs = this.supportManagersService
      .deleteOne(this.selectedUser.id)
      .subscribe(() => {
        this.users = this.users.filter(c => c.id !== this.selectedUser.id);
        this.toast = {
          message: `User ${this.selectedUser.first_name} ${this.selectedUser.last_name} was successfully deleted`,
          type: 'success'
        };
        this.selectedUser = null;
      }, () => {
        this.toast = {
          message: 'Cannot remove the user. Please try again.',
          type: 'danger'
        };
      });
  }

  public handleUserDialogSubmit(user: User): void {
    if (this.selectedUser) {
      const existingUser = this.users.filter((i) => i.id === this.selectedUser.id)[0];
      Object.assign(existingUser, user);
      this.toast = {
        message: `User ${user.first_name} ${user.last_name} was successfully updated`,
        type: 'success'
      };
      this.selectedUser = null;
    } else {
      this.users = [
        ...this.users,
        user
      ];
      this.toast = {
        message: `User ${user.first_name} ${user.last_name} was successfully created`,
        type: 'success'
      };
    }
  }
}


import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from '../../shared/base-component/base-component.component';
import { User } from '../../types/user';
import { Toast } from '../../shared/toast-message/toast';
import { ValidationService } from '../../shared/validators/validators.service';
import { CallReviewersService } from '../../api/call-reviewers.service';

@Component({
  selector: 'up-call-reviewer-dialog',
  templateUrl: './call-reviewer-dialog.component.html',
  styleUrls: ['./call-reviewer-dialog.component.scss']
})
export class CallReviewerDialogComponent extends BaseComponent implements OnInit {
  @Input() user?: User;

  public toast: Toast;
  public loading: boolean;
  public reviewerForm: FormGroup;
  public isFormSubmitted: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    public activeModal: NgbActiveModal,
    private callReviewersService: CallReviewersService
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.setForm();
  }

  public submitReviewerForm(): void {
    if (!this.reviewerForm.valid) {
      this.isFormSubmitted = true;
      this.validationService.markAllControlsAsTouched(this.reviewerForm);
      return;
    }
    this.loading = true;
    let request;
    if (this.user) {
      request = this.callReviewersService.update(this.reviewerForm.value, this.user.id);
    } else {
      const params = this.reviewerForm.value;
      params.role = 'call_reviewer';
      request = this.callReviewersService.create(params);
    }
    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (result) => {
          this.activeModal.close(result);
        },
        (err) => {
          let msg;
          if (err && err.status === 409) {
            if (err.error.error.email) {
              msg = 'User with this email already exists.';
            }
          } else {
            if (err && err.error.error.first_name) {
              msg = `Valid first name should be provided`;
            } else if (err && err.error.error.last_name) {
              msg = `Valid last name should be provided`;
            } else {
              msg = `Cannot ${this.user ? 'modify' : 'create'} the user. Please try again.`;
            }
          }
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });

  }

  private createForm() {
    this.reviewerForm = this.formBuilder.group({
      first_name: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]],
      last_name: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]]
    });
  }

  private setForm() {
    this.reviewerForm.patchValue(this.user || {});
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallReviewersComponent } from './call-reviewers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CallReviewerDialogComponent } from './call-reviewer-dialog/call-reviewer-dialog.component';
import { CallReviewerReportDialogComponent } from './call-reviewer-report-dialog/call-reviewer-report-dialog.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Call Reviewers'
    },
    component: CallReviewersComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  declarations: [CallReviewersComponent, CallReviewerDialogComponent, CallReviewerReportDialogComponent],
  entryComponents: [CallReviewerDialogComponent, CallReviewerReportDialogComponent]
})
export class CallReviewersModule {
}

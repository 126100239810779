import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportManagersComponent } from './support-managers.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SupportManagersDialogComponent } from './support-manager-dialog/support-manager-dialog.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Support Managers'
    },
    component: SupportManagersComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  declarations: [ SupportManagersComponent, SupportManagersDialogComponent ]
})
export class SupportManagersModule { }

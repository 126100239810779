import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import { ReviewCall, ReviewCallAudioUrl } from '../types/review-call';

export interface CallResponse {
  call: ReviewCall;
  callsLeftToReviewCount: number;
}

interface ResponseData<T = any> {
  data: T;
  success: boolean;
}

interface ResponseGetAudioUrl<T = any> {
  url: T;
  success: boolean;
}

@Injectable()
export class CallsService {

  constructor(private http: HttpClient) {
  }

  getNewCalls(): Observable<CallResponse> {
    return this.http.get<ResponseData<CallResponse>>(`api/v1/calls`)
      .map(({ data }) => data);
  }

  getCallsByCompany(companyExtId: string): Observable<CallResponse> {
    return this.http.get<ResponseData<CallResponse>>(`api/v1/calls?companyExternalId=${companyExtId}`)
      .map(({ data }) => data);
  }

  getCallsCountByCompany(companyExtId: string): Observable<CallResponse> {
    return this.http.get<ResponseData<CallResponse>>(`api/v1/calls/count?companyExternalId=${companyExtId}`)
      .map(({ data }) => data);
  }

  updateCall(companyId: string, callId: string, callData: any): Observable<ReviewCall> {
    return this.http.put<ResponseData<ReviewCall>>(`api/v1/companies/${companyId}/stats/update/${callId}`, { ...callData })
      .map(({ data }) => data);
  }

  getCall(companyId: string, callId: string): Observable<ReviewCall> {
    return this.http.get<ResponseData<ReviewCall>>(`api/v1/companies/${companyId}/stats/${callId}`)
      .map(({ data }) => data);
  }

  getAudioUrl(companyExtId: string, callId: string): Observable<any> {
    return this.http.get<ResponseGetAudioUrl<ReviewCallAudioUrl>>(`api/v1/companies/${companyExtId}/calls/${callId}/audio/url`,
      {
        withCredentials: true
      })
      .map(({ url }) => url);
  }

}

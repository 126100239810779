import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[upAgentId]'
})
export class AgentIdDirective {
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange() {
    const initialValue = this._el.nativeElement.value;

    const trimmedValue = initialValue.replace(/[^0-9]*/g, '').slice(-2);
    const paddedValue = trimmedValue ? trimmedValue.padStart(2, '0') : '';

    this._el.nativeElement.value = paddedValue;
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import * as Slider from 'bootstrap-slider';

@Component({
  selector: 'up-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss', './audio-player.slider.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {
  public PLAYBACK_RATES: number[] = [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0];
  public player: HTMLAudioElement;
  public progressSliderValue: HTMLInputElement;

  @ViewChild('playerElement')
  set playerElement(el: ElementRef) {
    this.player = el.nativeElement;
    this.changeDetectorRef.detectChanges();
  }

  @ViewChild('playbackSliderValue')
  set playbackSliderValue(el: ElementRef) {
    this.progressSliderValue = el.nativeElement;
  }

  @Input()
  sourceUrl: string;

  public playbackRate = 1.25;
  public progress = 0;
  public playing = false;
  public showPlayer = false;
  public progressSlider: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.showPlayer = this.sourceAvailable();
  }

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      this.createProgressSlider();
    });
  }

  private createProgressSlider(): void {
    this.progressSlider = new Slider('#playbackSliderValue', {
      formatter: value => {
        return 'Value: ' + value;
      }
    });
    this.progressSlider.enabled = true;
    this.progressSlider.on('slideStart', () => {
      this.pausePlayback();
    });
    this.progressSlider.on('slide', () => {
      this.pausePlayback();
    });
    this.progressSlider.on('slideStop', value => {
      this.setCurrentTime((this.player.duration / 100) * value);
      const playPromise = this.player.play();
      if (playPromise) {
        playPromise.then(() => {
          this.playing = true;
        });
      }
    });
  }

  private sourceAvailable(): boolean {
    return (this.showPlayer = this.sourceUrl !== null && this.sourceUrl !== '');
  }

  public startPlayback(): void {
    if (!this.sourceAvailable()) {
      return;
    }
    if (this.player) {
      const playPromise = this.player.play();
      if (playPromise) {
        playPromise.then(() => {
          this.playing = true;
        });
      }
    }
  }

  public pausePlayback(): void {
    if (!this.sourceAvailable()) {
      return;
    }
    if (this.player) {
      this.playing = false;
      this.player.pause();
    }
  }

  public playbackVolume(volume: number): void {
    if (!this.sourceAvailable()) {
      return;
    }
    if (this.player) {
      this.player.volume = volume;
    }
  }

  public startPlaybackAtSeconds(seconds: number): void { // This method is using in the parent component.
    this.setCurrentTime(seconds);
    this.startPlayback();
  }

  public updateProgress(): void {
    this.progress = this.player ? Math.ceil((this.player.currentTime / this.player.duration) * 100) : 0;
    this.progressSlider.setValue(this.progress);
  }

  public getDuration(): number {
    return this.player ? Math.floor(this.player.duration) : 0;
  }

  public getCurrentTime(): number {
    return this.player ? Math.floor(this.player.currentTime) : 0;
  }

  public getTimeRemaining(): number {
    return this.player ? Math.floor(this.player.duration) - Math.floor(this.player.currentTime) : 0;
  }

  public getCurrentTimeFormatted(): string {
    const time: number = this.getCurrentTime() || 0;
    return this.formatTime(time);
  }

  public getTimeRemainingFormatted(): string {
    const time: number = this.getTimeRemaining() || 0;
    return '-' + this.formatTime(time);
  }

  private formatTime(time: number = 0): string {
    const minutes: string = String(Math.floor(time / 60));
    const seconds: string = ('00' + (time % 60)).slice(-2);
    return minutes + ':' + seconds;
  }

  private setCurrentTime(seconds: number = 0): void {
    if (this.player) {
      this.player.currentTime = seconds;
    }
  }

  public setPlaybackRate(rate: number, event: any = null): void {
    this.playbackRate = rate;
  }
}

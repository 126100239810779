import { Component, OnInit } from '@angular/core';
import { User } from '../types/user';
import { Toast } from '../shared/toast-message/toast';
import { TableHeaderItem } from '../shared/table-base/table-header-item.interface';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { UsersService } from '../api/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallReviewerDialogComponent } from './call-reviewer-dialog/call-reviewer-dialog.component';
import { CallReviewersService } from '../api/call-reviewers.service';
import { CallReviewerReportDialogComponent } from './call-reviewer-report-dialog/call-reviewer-report-dialog.component';
import { saveAs } from 'file-saver';

declare const $: any;

@Component({
  templateUrl: './call-reviewers.component.html',
  styleUrls: ['./call-reviewers.component.scss']
})
export class CallReviewersComponent extends BaseComponent implements OnInit {
  public users: User[];
  public searchString: string;
  public selectedUser: User;
  public toast: Toast;
  public header: TableHeaderItem[] = [
    {
      key: 'first_name',
      title: 'First name'
    },
    {
      key: 'last_name',
      title: 'Last name'
    },
    {
      key: 'email',
      title: 'Email'
    },
    {
      key: 'actions',
      title: 'Actions'
    }
  ];

  constructor(
    private usersService: UsersService,
    private modalService: NgbModal,
    private callReviewersService: CallReviewersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs = this.callReviewersService
      .getAll()
      .subscribe(users => this.users = users);
  }

  public sendWelcomeEmail(user: User): void {
    this.selectedUser = user;
    $('#send-email-dialog').modal('show');
  }

  public showDeleteUserDialog(user: User): void {
    this.selectedUser = user;
    $('#delete-user-dialog').modal('show');
  }

  public sendEmail(): void {
    this.subs = this.usersService
      .sendEmail(this.selectedUser.id)
      .subscribe(
        () => {
          this.toast = {
            message: 'Email was sent.',
            type: 'success'
          };
        },
        () => {
          this.toast = {
            message: 'Cannot send email. Please try again.',
            type: 'danger'
          };
        });
  }

  public deleteUser(): void {
    this.subs = this.callReviewersService
      .deleteOne(this.selectedUser.id)
      .subscribe(() => {
        this.users = this.users.filter(c => c.id !== this.selectedUser.id);
        this.toast = {
          message: `Call Reviewer ${this.selectedUser.first_name} ${this.selectedUser.last_name} was successfully deleted`,
          type: 'success'
        };
        this.selectedUser = null;
      }, () => {
        this.toast = {
          message: 'Cannot remove the user. Please try again.',
          type: 'danger'
        };
      });
  }

  public createReviewer(): void {
    const modalRef = this.modalService.open(CallReviewerDialogComponent);
    modalRef.result.then(result => {
      this.handleUserDialogSubmit(result);
    }, () => {});
  }

  public editReviewer(user: User): void {
    this.selectedUser = {...user};
    const modalRef = this.modalService.open(CallReviewerDialogComponent);
    modalRef.componentInstance.user = this.selectedUser;
    modalRef.result.then(result => {
      this.handleUserDialogSubmit(result);
    }, () => {});
  }

  private handleUserDialogSubmit(user: User): void {
    if (this.selectedUser) {
      const existingUser = this.users.filter((i) => i.id === this.selectedUser.id)[0];
      Object.assign(existingUser, user);
      this.toast = {
        message: `Call Reviewer ${user.first_name} ${user.last_name} was successfully updated`,
        type: 'success'
      };
      this.selectedUser = null;
    } else {
      this.users = [
        ...this.users,
        user
      ];
      this.toast = {
        message: `Call Reviewer ${user.first_name} ${user.last_name} was successfully created`,
        type: 'success'
      };
    }
  }

  public getCallReviewerReport(): void {
    const modalRef = this.modalService.open(CallReviewerReportDialogComponent);
    modalRef.result.then(result => {
      this.handleCallReviewerReportDialogSubmit(result);
    }, () => {});
  }

  private handleCallReviewerReportDialogSubmit(result: any) {
    const file = new File([result.activityLog], `call-reviewer-report-${result.fromDate}-to-${result.toDate}.csv`, {type: 'text/plain;charset=utf-8'});
    saveAs(file);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RewardsLogComponent } from './rewards-log.component';
import { SharedModule } from '../shared/shared.module';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExpandableContentComponent } from './slide-content/expandable-content.component';

const routes: Routes = [
  {
    path: '',
    component: RewardsLogComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NguiAutoCompleteModule
  ],
  declarations: [RewardsLogComponent, ExpandableContentComponent]
})
export class RewardsLogModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatId'
})
export class FormatIdPipe implements PipeTransform {
  transform(value: any): string {
    const kazooIdIndex = value.toString().indexOf('@');
    if (kazooIdIndex > 0) {
      return value.toString().substr(kazooIdIndex - 4, 4);
    } else {
      return value.toString().substr(-4);
    }
  }
}

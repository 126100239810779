import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CompaniesModule } from '../companies/companies.module';
import { RouterModule } from '@angular/router';
import { PhoneNumberDialog } from './phone-number-dialog/phone-number.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MainRoutingModule,
    CompaniesModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InternationalPhoneNumberModule
  ],
  declarations: [MainComponent, PhoneNumberDialog],
  entryComponents: [PhoneNumberDialog]
})
export class MainModule { }

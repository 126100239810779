import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableBaseComponent } from './table-base/table-base.component';
import { DialogBaseComponent } from './dialog-base/dialog-base.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { StripeTestComponent } from './stripe-component/stripe.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { ChatSettingsComponent } from './chat-settings/chat-settings.component';

export const SharedComponents = [
  ControlMessagesComponent,
  ToastMessageComponent,
  HeaderComponent,
  FooterComponent,
  SidebarComponent,
  TableBaseComponent,
  DialogBaseComponent,
  ConfirmDialogComponent,
  TooltipDirective,
  StripeTestComponent,
  AudioPlayerComponent,
  ChatSettingsComponent
];

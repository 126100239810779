import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewMember } from '../review-calls.component';

@Component({
  selector: 'up-member-lookup',
  templateUrl: './member-lookup.component.html',
  styleUrls: ['./member-lookup.component.scss']
})
export class MemberLookupComponent {
  @Input() searchData: ReviewMember[];
  @Output() selectedMember: EventEmitter<string> = new EventEmitter<string>();
  public memberData: ReviewMember;
  public disabledText: Boolean;

  public onSelected(item: ReviewMember | any): void {
    if (item) {
      this.searchData.forEach(m => {
        if ((m.title === item.title) && (m.value === item.value)) {
          this.disabledText = true;
          this.selectedMember.emit(item.value);
        }
      });
    } else {
      this.disabledText = false;
      this.memberData = null;
      this.selectedMember.emit('');
    }
  }
}

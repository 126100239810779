import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'filter',  pure: false })
export class FilterPipe implements PipeTransform {
  transform(array: any, fields: string[], value: any): any {
    if (!Array.isArray(array) || !value) {
      return array;
    }
    return array.filter(item => {
      return fields.some(key => {
        let rowValue = item[key];
        if (rowValue === null || rowValue === undefined) {
          return false;
        }
        if (key === 'createdAt') {
          const date = moment(item[key]);
          if (date.isValid()) {
            rowValue = date.format('MM-DD-Y');
          }
        }
        return rowValue.toString().toLowerCase().includes(value.toLowerCase());
      });
    });

  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

declare let $: any;

@Component({
  selector: 'up-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: []
})
export class ConfirmDialogComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog;

  @Input() id: string;
  @Input() message: string;
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onConfirm() {
    this.confirm.emit();
    $(`#${this.id}`).modal('hide');
  }

}

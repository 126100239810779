import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const Amplify = window['aws-amplify'];
Amplify.configure = function() {
  return {};
};
Amplify.Auth.configure({
  region: 'ca-central-1',
  userPoolId: environment.COGNITO_USER_POOL_ID,
  userPoolWebClientId: environment.COGNITO_CLIENT_ID,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { UsersService } from '../../api/users.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Reward } from '../../types/reward';
import { TableHeaderItem } from '../../shared/table-base/table-header-item.interface';

@Component({
  selector: 'up-rewards-log-individual',
  templateUrl: './rewards-log-individual.component.html',
  styleUrls: ['./rewards-log-individual.component.scss']
})
export class RewardsLogIndividualComponent extends BaseComponent implements OnInit {
  public loading: Boolean;
  public logs: Reward[];
  public header: TableHeaderItem[] = [
    {
      key: 'createdAt',
      title: 'Date'
    },
    {
      key: 'user_name',
      title: 'User name'
    },
    {
      key: 'company_name',
      title: 'Company Name'
    },
    {
      key: 'transaction_id',
      title: 'Transaction'
    },
    {
      key: 'reward_credit',
      title: 'Reward Credit'
    },
    {
      key: 'reward_debit',
      title: 'Reward Debit'
    }
  ];
  public searchString = '';

  constructor(private usersService: UsersService) {
    super();
  }

  ngOnInit() {
    this.getInitData();
  }

  private getInitData(): void {
    this.loading = true;
    this.subs = this.usersService
      .getRewardsLog()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(result => (this.logs = result.rewards));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ChatConfig } from '../../types/company';
import { Toast } from '../toast-message/toast';
import { ValidationService } from '../validators/validators.service';

@Component({
  selector: 'up-chat-settings',
  templateUrl: './chat-settings.component.html',
  styleUrls: ['./chat-settings.component.scss']
})
export class ChatSettingsComponent implements OnInit {
  @Input() chatConfiguration?: ChatConfig;
  @Output() saveChatConfig = new EventEmitter();

  public chatForm: FormGroup;
  public toast: Toast;
  public startTime: any;
  public endTime: any;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService
  ) {
    this.createChatForm();
  }

  ngOnInit() {
    if (this.chatConfiguration) {
      const formValue = { ...this.chatConfiguration };
      formValue.DIDs = (formValue.DIDs as string[]).join(', ');
      this.startTime = {
        hour: this.chatConfiguration.officeHours.startTime.split(':')[0],
        minute: this.chatConfiguration.officeHours.startTime.split(':')[1]
      };
      this.endTime = {
        hour: this.chatConfiguration.officeHours.endTime.split(':')[0],
        minute: this.chatConfiguration.officeHours.endTime.split(':')[1]
      };
      this.chatForm.patchValue(formValue);
    }
  }

  private createChatForm(): void {
    this.chatForm = this.fb.group({
      smsGatewaySettings: this.fb.group({
        apiToken: ['', [Validators.required]],
        apiSecret: ['', [Validators.required]],
        userId: ['', [Validators.required]],
        defaultDID: ['', [Validators.required]],
        applicationId: ['', [Validators.required]],
      }),
      DIDs: ['', [Validators.required]],
    });
  }

  public saveChatForm(): void {
    this.toast = null;
    if (!this.startTime || !this.endTime) {
      this.toast = {
        message: 'Please fill hours range.',
        type: 'danger'
      };
      return;
    }
    if (!this.chatForm.valid) {
      this.validationService.markAllControlsAsTouched(this.chatForm);
      return;
    }
    const chatData: ChatConfig = {
      officeHours: {
        startTime: `${this.startTime.hour}:${this.startTime.minute}`,
        endTime: `${this.endTime.hour}:${this.endTime.minute}`
      },
      ... this.chatForm.value
    };
    chatData.DIDs = (chatData.DIDs as string).replace(/\s/g, '').split(',');
    this.saveChatConfig.emit(chatData);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesService } from './companies.service';
import { CurrentUserService } from './current-user.service';
import { UsersService } from './users.service';
import { SupportManagersService} from './supportManagers.service';
import { PaymentsService } from './payments.service';
import { CallsService } from './calls.service';
import { CallReviewersService } from './call-reviewers.service';
import { EventsService } from './events.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    CompaniesService,
    CurrentUserService,
    UsersService,
    SupportManagersService,
    PaymentsService,
    CallsService,
    EventsService,
    CallReviewersService
  ],
  declarations: []
})
export class ApiModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import * as _ from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as uuid from 'uuid/v4';

import { BaseComponent } from '../shared/base-component/base-component.component';
import { CompaniesService } from '../api/companies.service';
import { CurrentUserService } from '../api/current-user.service';
import { CallResponse, CallsService } from '../api/calls.service';
import { Company } from '../types/company';
import { Toast } from '../shared/toast-message/toast';
import { AddMemberDialogComponent } from './add-member-dialog/add-member-dialog.component';
import { UsersService } from '../api/users.service';
import { User } from '../types/user';
import { ReviewCall } from '../types/review-call';
const { Storage } = window['aws-amplify'];

export interface ReviewMember {
  title: string;
  value: string;
}

interface PlayerCall {
  audio: string;
  resolvedAudio: String;
}

declare let $: any;

@Component({
  selector: 'up-review-calls',
  templateUrl: './review-calls.component.html',
  styleUrls: ['./review-calls.component.scss']
})

export class ReviewCallsComponent extends BaseComponent implements OnInit {
  @ViewChild('player') player: any;

  public toast: Toast;
  public loading: boolean;
  public saving: boolean;
  public companies: Company[] = [];
  public selectedCompany: Company;
  public currentUser: User;
  public members: ReviewMember[];
  public currentCall: ReviewCall;
  public call: PlayerCall = {
    audio: '',
    resolvedAudio: ''
  };
  public reviewedCallsIds: string[] = [];
  public previousCallId: string;
  public previousCompanyId: string;
  public selectedMemberExtId: string;
  private playedCallEnd: boolean;
  public callBookStatus: boolean;
  public voiceMail: boolean;
  public existingClient: boolean;
  public callIneligible: boolean;
  public notBooked: boolean;
  public noCompany: boolean;
  public countdown: number;
  public timer: any;
  public callsLeftToReview: number;
  private reviewByCompany: boolean;
  private statusSet: boolean;

  constructor(
    private companiesService: CompaniesService,
    private currentUserService: CurrentUserService,
    private hotKeysService: HotkeysService,
    private callsService: CallsService,
    private modalService: NgbModal,
    private usersService: UsersService
  ) {
    super();

    this.hotKeysService.add(new Hotkey('shift+b', (): boolean => {
      this.callStatus('BOOKED');
      return false;
    }));

    this.hotKeysService.add(new Hotkey('shift+n', (): boolean => {
      this.callStatus('NOTBOOKED');
      return false;
    }));

    this.hotKeysService.add(new Hotkey('shift+i', (): boolean => {
      this.callStatus('INELIGIBLE');
      return false;
    }));

    this.hotKeysService.add(new Hotkey('shift+v', (): boolean => {
      this.callStatus('VOICEMAIL');
      return false;
    }));

    this.hotKeysService.add(new Hotkey('shift+e', (): boolean => {
      this.callStatus('EXISTINGCLIENT');
      return false;
    }));

    this.hotKeysService.add(new Hotkey('enter', (): boolean => {
      this.submitCallReview();
      return false;
    }));

    this.hotKeysService.add(new Hotkey('shift+f', (): boolean => {
      $('#memberData').focus();
      return false;
    }));

    this.hotKeysService.add(new Hotkey('shift+m', (): boolean => {
      this.showAddMemberDialog();
      return false;
    }));

    this.hotKeysService.add(new Hotkey('space', (): boolean => {
      if (!this.player.playing) {
        this.player.startPlayback();
      } else {
        this.player.pausePlayback();
      }
      return false;
    }));
  }

  ngOnInit() {
    this.loading = true;
    this.currentUser = this.currentUserService.getUser();
    this.subs = this.companiesService.getKazooCompanies()
      .subscribe(
        (data: Company[]) => {
          this.companies = data;
          this.getAllCalls();
        },
        () => {
          this.toast = {
            message: 'Something goes bad. Please reload page and try again.',
            type: 'danger'
          };
          this.loading = false;
        });
  }

  public startCountdown(amount): void {
    clearInterval(this.timer);
    const that = this;
    this.countdown = amount;
    this.timer = setInterval(function () {
      that.countdown--;
    }, 1000);
  }

  private getAllCalls(): void {
    this.startCountdown(30);
    this.loading = true;
    this.toast = null;
    this.noCompany = false;
    const request = !this.reviewByCompany ?
      this.callsService.getNewCalls() :
      this.callsService.getCallsByCompany(this.selectedCompany.kazoo_external_id || this.selectedCompany.external_id);
    this.subs = request
      .subscribe(
        (data: CallResponse) => {
          this.currentCall = data.call;
          if (!data.call || (data && data.call && data.call.reviewed)) {
            this.loading = false;
            this.currentCall = null;
            this.noCompany = true;
          } else if (this.reviewedCallsIds.indexOf(this.currentCall.id) !== -1) {
            this.resetCall();
            this.getAllCalls();
          } else {
            this.loading = false;
            this.callsLeftToReview = data.callsLeftToReviewCount;
            if (!this.reviewByCompany) {
              this.selectedCompany = this.getCompanyByExternalId(this.currentCall.external_id);
              if (this.selectedCompany && this.selectedCompany.id) {
                this.selectCompany(this.selectedCompany.id);
              } else {
                this.noCompanyHandler();
              }
            }

            this.subs = this.callsService.getAudioUrl(this.currentCall.external_id, this.currentCall.call_id_hipaa)
              .subscribe(resolvedAudio => {
                this.call.resolvedAudio = resolvedAudio;
              });
          }
        },
        () => {
          this.toast = {
            message: 'Can not load a call. Seems no calls for review in current company.',
            type: 'danger'
          };
          this.loading = false;
          this.resetCall();
        });
  }

  private getCompanyByExternalId(externalId): Company {
    return _.find(this.companies, ['kazoo_external_id', externalId]);
  }

  public callStatus(status: string): void {
    this.statusSet = true;
    if (status === 'BOOKED') {
      this.callBookStatus = true;
      this.notBooked = false;
    } else if (status === 'NOTBOOKED') {
      this.notBooked = true;
      this.callBookStatus = false;
    } else if (status === 'INELIGIBLE') {
      this.callIneligible = !this.callIneligible;
    } else if (status === 'VOICEMAIL') {
      this.voiceMail = !this.voiceMail;
    } else if (status === 'EXISTINGCLIENT') {
      this.existingClient = !this.existingClient;
    }
  }

  private resetCall(prevCall?: boolean): void {
    this.statusSet = false;
    this.notBooked = false;
    this.callIneligible = false;
    this.callBookStatus = false;
    this.voiceMail = false;
    this.existingClient = false;
    this.noCompany = false;
    this.saving = false;
    this.selectedMemberExtId = '';
    this.members = this.reviewByCompany ? this.members : [];
    this.currentCall = null;
    this.call.audio = '';
    this.call.resolvedAudio = '';
    if (prevCall) {
      this.previousCallId = '';
      this.previousCompanyId = '';
    }
  }

  private selectCompany(id: string): void {
    this.subs = this.companiesService
      .getCompanyUsers(id)
      .subscribe((data: User[]) => {
        this.members = data.map(m => {
          return {
            title: m.first_name + ' ' + m.last_name,
            value: m.external_id
          };
        });
        this.noCompany = false;
      });
  }

  private noCompanyHandler(): void {
    this.resetCall();
    this.noCompany = true;
  }

  public showNextCall(): void {
    this.loading = true;
    this.resetCall();
    this.toast = null;
    this.noCompany = false;
    this.getAllCalls();
  }

  public showAddMemberDialog(): void {
    const modalRef = this.modalService.open(AddMemberDialogComponent);
    modalRef.result.then(result => {
      this.createMember(result);
    }, () => {
    });
  }

  public submitCallReview(): void {
    if (!this.callIneligible && !this.voiceMail && !this.existingClient && (!this.selectedMemberExtId || !this.statusSet)) {
      this.toast = {
        message: 'Please select company member and set call status first.',
        type: 'danger'
      };
      return;
    }
    this.loading = true;
    this.saving = true;
    this.toast = null;
    const callUpdateData = {
      memberExternalId: this.selectedMemberExtId,
      ineligibleScore: this.callIneligible,
      appointmentBooked: this.callBookStatus,
      voiceMail: this.voiceMail,
      existingClient: this.existingClient
    };
    this.startCountdown(30);
    this.subs = this.callsService
      .updateCall(this.selectedCompany.id, this.currentCall.call_id_hipaa, callUpdateData)
      .subscribe(
        () => {
          this.reviewedCallsIds.push(this.currentCall.id);
          this.previousCallId = this.currentCall.call_id_hipaa;
          this.previousCompanyId = this.selectedCompany.id;
          this.resetCall();
          this.getAllCalls();
        },
        error => {
          this.toast = {
            message: error.message || 'Error: Please reload page and try again.',
            type: 'danger'
          };
          this.loading = false;
        });
  }

  public backToPreviousCall(): void {
    this.loading = true;
    this.toast = null;
    this.subs = this.callsService.getCall(this.previousCompanyId, this.previousCallId)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (call: ReviewCall) => {
          this.resetCall(true);
          this.currentCall = call;
          this.callsLeftToReview = this.callsLeftToReview + 1;
          if (!this.reviewByCompany) {
            this.selectedCompany = this.getCompanyByExternalId(this.currentCall.external_id);
            this.selectCompany(this.selectedCompany.id);
          }

          this.subs = this.callsService.getAudioUrl(this.currentCall.external_id, this.currentCall.call_id_hipaa)
            .subscribe(resolvedAudio => {
              this.call.resolvedAudio = resolvedAudio;
            });
        },
        error => {
          this.toast = {
            message: error.message || 'Error: Please reload page and try again.',
            type: 'danger'
          };
        });
  }

  private startPlaybackAtSeconds(seconds: number = 0): void {
    if (this.player) {
      this.player.startPlaybackAtSeconds(seconds);
    }
  }

  private startPlaybackOfCallEnd(): void {
    if (!this.playedCallEnd && this.player) {
      const lastSeconds = this.player.getDuration() - 20 > 0 ? this.player.getDuration() - 20 : 0;
      this.startPlaybackAtSeconds(lastSeconds);
      this.playedCallEnd = true;
    }
  }

  private createMember(memberData: any): void {
    this.loading = true;
    this.toast = null;
    const params = memberData;
    params.last_name = 'UPbook';
    params.role = 'member';
    params.external_id = uuid();
    params.username = uuid();
    params._company = this.selectedCompany.id;
    this.usersService.createUser(params)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        () => {
          this.selectCompany(this.selectedCompany.id);
        }, error => {
          this.toast = {
            message: error.message || 'Cannot create a member. Please reload page and try again.',
            type: 'danger'
          };
        }
      );
  }

  public onMemberLookup(memberExtId: string | null): void {
    this.selectedMemberExtId = memberExtId;
    this.playedCallEnd = false;
    if (this.selectedMemberExtId) {
      this.startPlaybackOfCallEnd();
    }
  }

  public onCompanySelect(company: Company | null): void {
    if (company) {
      this.selectedCompany = company;
      this.reviewByCompany = true;
      this.resetCall();
      this.selectCompany(this.selectedCompany.id);
      this.getAllCalls();
    } else {
      this.reviewByCompany = false;
      this.resetCall();
      this.getAllCalls();
    }
  }
}

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Toast } from './toast';

@Component({
  selector: 'up-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit, OnChanges {
  @Input() toast: Toast;

  public visible = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    this.visible = true;
  }



}

import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { UsersService } from '../../api/users.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Reward } from '../../types/reward';
import { TableHeaderItem } from '../../shared/table-base/table-header-item.interface';

@Component({
  selector: 'up-rewards-log-totals',
  templateUrl: './rewards-log-totals.component.html',
  styleUrls: ['./rewards-log-totals.component.scss']
})
export class RewardsLogTotalsComponent extends BaseComponent implements OnInit {
  public loading: Boolean;
  public logs: Reward[];
  public header: TableHeaderItem[] = [
    {
      key: 'day',
      title: 'Date'
    },
    {
      key: 'company_name',
      title: 'Company Name'
    },
    {
      key: 'total',
      title: 'Total Reward Credit'
    }
  ];
  public searchString = '';

  constructor(private usersService: UsersService) {
    super();
  }

  ngOnInit() {
    this.getInitData();
  }

  private getInitData(): void {
    this.loading = true;
    this.subs = this.usersService
      .getRewardsLogTotals()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(result => (this.logs = result.rewards));
  }
}

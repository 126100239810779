import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export type EventData =
  | { companies: object[]; rewards: string | { status: string } }
  | { teamMemberIndividualRewardReceived: object }
  | { memberRedeemedRewardForCard: object }
  | { rewardCardOrder: object }
  | { expiredRewardPeriod: object };

export interface Event {
  id: string;
  createdAt: string;
  data: EventData;
}

@Injectable()
export class EventsService {
  constructor(private http: HttpClient) {}

  get(skip = 0, limit = 100) {
    const queryString = `?skip=${skip}&limit=${limit}`;
    return this.http.get<{ data: Event[] }>(`api/v1/events${queryString}`).pipe(map(({ data }) => data));
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'up-expandable-content',
  templateUrl: './expandable-content.component.html',
  styleUrls: ['./expandable-content.component.scss']
})
export class ExpandableContentComponent {
  visible = false;
}

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CurrentUserService } from '../api/current-user.service';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private currentUserService: CurrentUserService) {}

  canActivate(): Observable<boolean> {
    return this.currentUserService.checkSession().pipe(
      map(res => {
        if (!res.success) {
          this.currentUserService.logout();
        }
        return res.success;
      }),
      catchError(() => {
        this.currentUserService.logout();
        return of(false);
      })
    );
  }
}

import { Component } from '@angular/core';
import { CurrentUserService } from '../../api/current-user.service';

@Component({
  selector: 'up-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent {
  public user;

  constructor(private currentUser: CurrentUserService) {
    this.user = this.currentUser.getUser();
  }
}

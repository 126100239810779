import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiModule } from './api/api.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from './api/http-interseptor';
import { HttpClientModule } from '@angular/common/http';
import { MainModule } from './main/main.module';
import { NgxStripeModule } from 'ngx-stripe';
import { HotkeyModule, HotkeysService } from 'angular2-hotkeys';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { CurrentUserService } from './api/current-user.service';
import { loadUser } from './main/load-user';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    NgbModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_dtdKfPKjqXN7jMcNyWtBE2Qh'),
    ApiModule,
    MainModule,
    AmplifyAngularModule,
    HotkeyModule.forRoot(),
    NguiAutoCompleteModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadUser,
      deps: [CurrentUserService],
      multi: true
    },
    HotkeysService,
    {
      provide: AmplifyService,
      useFactory: () => new AmplifyService({ Auth: window['aws-amplify']['Auth'] })
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, OnInit, Input, OnChanges, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/operator/finally';
import * as _ from 'lodash-es';
import * as moment from 'moment';

import { ValidationService } from '../../shared/validators/validators.service';
import { CompaniesService } from '../../api/companies.service';
import { PaymentsService } from '../../api/payments.service';
import { CallsService } from '../../api/calls.service';
import { Toast } from '../../shared/toast-message/toast';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { ChatConfig, Company, Features, PaymentCompany } from '../../types/company';

declare let $: any;

@Component({
  selector: 'up-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() id: string;
  @Input() company?: Company;
  @Output() companySubmit = new EventEmitter();
  @ViewChild('tabs') tabSet: NgbTabset;

  public toast: Toast;
  public loading: boolean;
  public companyForm: FormGroup;
  public gravityForm: FormGroup;
  public isFormSubmitted: boolean;
  public isModalForKazooUpdatesSubmitted: boolean;
  public companyFeatures: Features = {};
  public customerData: PaymentCompany;
  public chatConfiguration: ChatConfig;
  public selectedCurrency = '';
  public currencyOptions = [{ id: 'usd', name: 'USD' }, { id: 'cad', name: 'CAD' }];
  private features: Features = {};
  Object = Object;

  constructor(private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private companiesService: CompaniesService,
              private callsService: CallsService,
              private paymentsService: PaymentsService) {
    super();
    this.createForm();
  }


  ngOnInit() {
    this.company = null;
    this.subs = this.companiesService.getFeaturesList()
      .subscribe(
        res => {
          this.features = res;
          this.companyFeatures = _.cloneDeep(this.features);
        },
        error => console.error(error)
      );
  }

  ngAfterViewInit() {
    $(`#${this.id}`).on('hide.bs.modal', () => {
      this.toast = null;
      this.resetForm();
      this.tabSet.select('tab-id1');
    });
  }

  ngOnChanges(changes) {
    this.toast = null;
    if (changes.company) {
      this.resetForm();
      this.companyFeatures = _.cloneDeep(this.features);
      this.company = changes.company.currentValue;
      this.getCustomerInfo();
      if (this.company && this.company.features) {
        this.companyFeatures = _.merge(this.companyFeatures, this.company.features);
      }
      if (this.company) {
        this.chatConfiguration = this.company.chatAppSettings || this.chatConfiguration;
      }
      this.setForm();
    }
  }

  private createForm() {
    this.companyForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]],
      callForwardingTrackingPhone: '',
      phone: '',
      external_id: '',
      kazoo_external_id: '',
      hipaa: [true],
      isShowFullBoard: [false],
      demo: {value: [false], disabled: true},
      currency: ['', [Validators.required]],
      eliteScoreMaxCalls: [2, [Validators.required]],
    });
    this.gravityForm = this.formBuilder.group({
      gravityOid: '',
      gravityAuthToken: '',
      paymentSmsTemplate: '',
      paymentDeviceNames: '',
      invoiceExpireDays: '',
      invoiceDueDays: '',
      notificationInvoicePaid: ['', [
        this.validationService.emaislList
      ]],
      notificationInvoiceExpired: ['', [
        this.validationService.emaislList
      ]],
      notificationInvoicePastDue: ['', [
        this.validationService.emaislList
      ]],
      notificationInvoiceCanceled: ['', [
        this.validationService.emaislList
      ]]
    }, {
      validator: this.validationService.invoicePastDueValidation
    });
  }

  private setForm(): void {
    this.companyForm.patchValue(this.company || {});
    if (this.company && !this.company.isNewCompany && this.company.isShowFullBoard) {
      this.companyForm.controls['isShowFullBoard'].disable();
    }
    if (this.company && !this.company.isNewCompany && this.company.currency) {
      this.selectedCurrency = this.company.currency.toLowerCase();
      this.companyForm.controls['currency'].patchValue({currency: this.selectedCurrency});
    }
    this.gravityForm.patchValue(this.company || {});
  }

  private getCustomerInfo(): void {
    if (this.company && this.company.stripeCustomerId && !this.company.demo) {
      this.subs = this.paymentsService.getCustomerdata(this.company.id)
        .subscribe(
          res => this.customerData = res,
          error => console.error(error)
        );
    }
  }

  private resetForm(): void {
    this.company = null;
    this.chatConfiguration = null;
    this.isModalForKazooUpdatesSubmitted = null;
    this.companyForm.reset({
      hipaa: true,
      isShowFullBoard: false
    });
    this.gravityForm.reset();
    this.companyForm.controls['isShowFullBoard'].enable();
  }

  public getDate(timestamp): string {
    return moment(moment(timestamp * 1000)).format('MMMM Do YYYY');
  }

  public handlePayment(event): void {
    this.company = event;
    this.companySubmit.emit(event);
    this.getCustomerInfo();
  }

  public createCompany(form, gravity): void {
    console.log(this.company)
    console.log(form.value)
    this.toast = null;
    if (!form.value.external_id && !form.value.kazoo_external_id && (this.company && !this.company.demo)) {
      this.toast = {
        message: 'Please fill CTM or Kazoo EXT id field. One of these fields should be filled.',
        type: 'danger'
      };
      return;
    }

    if ((this.company && !this.company.demo) && !form.value.kazoo_external_id && !form.value.kazoo_external_id &&
        this.company && this.company.kazoo_external_id && !this.isModalForKazooUpdatesSubmitted) {
      this.subs = this.callsService.getCallsCountByCompany(this.company.kazoo_external_id).subscribe(
        data => {
          const { callsLeftToReviewCount = 0 } = data;
          if (callsLeftToReviewCount) {
            if (confirm(`Are you sure to delete kazoo id, it will mark ${callsLeftToReviewCount} calls as reviewed`)) {
              this.isModalForKazooUpdatesSubmitted = true;
              this.createCompany(form, gravity);
            }
            return;
          }
        },
        () => {
          this.toast = {
            message: 'An error occurred when trying to provision the calls on review for this company.',
            type: 'danger'
          };
        }
      );
      return;
    }

    if (!form.valid || !gravity.valid) {
      this.isFormSubmitted = true;
      this.validationService.markAllControlsAsTouched(form);
      console.log('a', this.gravityForm)
      return;
    }
    this.loading = true;
    this.companyForm.controls['isShowFullBoard'].enable();
    form.value.external_id = form.value.external_id ? form.value.external_id.toString() : '';
    const dataCompany = {
      ...form.value,
      features: this.companyFeatures,
      ...gravity.value,
    };
    if (this.chatConfiguration) {
      dataCompany.chatAppSettings = this.chatConfiguration;
    }

    if (this.selectedCurrency) {
      dataCompany.currency = this.selectedCurrency;
    }
    const request = this.company ? this.companiesService.editCompany(this.company.id, dataCompany)
      : this.companiesService.createCompany(dataCompany);
    this.subs = request
      .finally(() => {
        this.loading = false;
        this.companyForm.controls['isShowFullBoard'].disable();
      })
      .subscribe(res => {
        const successMessage = this.company ? 'Company successfully updated' : 'Company successfully created';
        this.toast = {
          message: successMessage,
          type: 'success'
        };
        this.companySubmit.emit(res);
        this.company = res;
        this.getCustomerInfo();
      }, err => {
        let msg;
        if (err && err.status === 409) {
          const isNameExists = err.error.error.includes('name');
          msg = isNameExists ? 'Company with this name already exists.' : 'Company with this id already exists.';
        } else if (err && err.status === 400) {
          const isExtExists = err.error.error.external_id;
          msg = isExtExists ? isExtExists.msg : 'Please, add EXT ID.';
        } else if (err && err.status === 404) {
          msg = 'This Kazoo Account ID was not found.';
        } else {
          msg = `Cannot ${this.company ? 'modify' : 'create'} the company. Please try again.`;
        }
        this.toast = {
          message: msg,
          type: 'danger'
        };
      });
  }

  public startSubscription(): void {
    this.loading = true;
    this.subs = this.paymentsService
      .startSubscription(this.company.id, {})
      .finally(() => this.loading = false)
      .subscribe(result => {
        if (result && result.id) {
          this.customerData.subscriptions.unshift({
            current_period_end: result.current_period_end,
            current_period_start: result.current_period_start,
            id: result.id,
            plan: _.cloneDeep(result.plan)
          });
        }
      }, () => {
        this.toast = {
          message: 'Bank account should be verified. Please verify your bank account before start subscription.',
          type: 'danger'
        };
      });
  }

  public addChatConfiguration(chatConfig: ChatConfig): void {
    this.chatConfiguration = chatConfig;
    this.tabSet.select('tab-id1');
    this.createCompany(this.companyForm, this.gravityForm);
  }

  public changeCurrency(event) {
    this.selectedCurrency = event.target.value;
  }
}

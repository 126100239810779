import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CurrentUserService } from './current-user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

const baseUrl = environment.BASE_URL;
declare const $: any;

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(public currentUser: CurrentUserService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true
    });
    const params = {
      url: `${baseUrl}/${request.url}`
    };

    return next.handle(request.clone(params)).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          $('.modal').modal('hide');
          this.currentUser.logout();
        }
        return throwError(err);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import { User } from '../types/user';

@Injectable()
export class SupportManagersService {

  constructor(private http: HttpClient) { }

  create(data): Observable<User> {
    return <Observable<any>> this.http.post(`api/v1/support-users`, data).map((response: any) => response.data);
  }

  getAll(): Observable<User[]> {
    return <Observable<any>> this.http.get(`api/v1/support-users`).map((response: any) => response.data);
  }

  deleteOne(id: string): Observable<any> {
    return <Observable<any>> this.http.delete(`api/v1/support-users/${id}`).map((response: any) => response.data);
  }

  update(data, id: string): Observable<User> {
    return <Observable<any>> this.http.put(`api/v1/support-users/${id}`, data).map((response: any) => response.data);
  }
}

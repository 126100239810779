import { Component, Input } from '@angular/core';

@Component({
  selector: 'up-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: []
})
export class DialogBaseComponent {
  @Input() title: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';

@Injectable()
export class PaymentsService {

  constructor(private http: HttpClient) { }

  createCustomer(companyId, data): Observable<any> {
    return <Observable<any>> this.http.post(`api/v1/payments/customer/${companyId}`, data).map((response: any) => response.data);
  }

  startSubscription(companyId, data): Observable<any> {
    return <Observable<any>> this.http.post(`api/v1/payments/subscriptionStart/${companyId}`, data).map((response: any) => response.data);
  }

  getCustomerdata(companyId): Observable<any> {
    return <Observable<any>> this.http.get(`api/v1/payments/${companyId}`).map((response: any) => response.data);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { User } from '../types/user';
import { Response } from '../types/response';

import 'rxjs/add/operator/map';
import { RateHistoryItem, Reward } from '../types/reward';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {}

  sendEmail(id: string): Observable<User> {
    return this.http.post<User>(`api/v1/users/${id}/welcome-message`, null).map((response: any) => response.data);
  }

  sendPIN(id: string): Observable<User> {
    return this.http.post<User>(`api/v1/users/${id}/pin`, null).map((response: any) => response.data);
  }

  getCurrentUser(companyId?: string): Observable<User> {
    const query = companyId ? `?companyId=${companyId}` : '';
    return this.http.get<Response<User>>(`api/v1/users/me${query}`).map(res => res.data);
  }

  editUser(id: string, data): Observable<User> {
    return this.http.put<User>(`api/v1/users/${id}`, data).map((response: any) => response.data);
  }

  createUser(data): Observable<User> {
    return this.http.post<User>(`api/v1/users`, data).map((response: any) => response.data);
  }

  getRewardsLog(): Observable<any> {
    return this.http.get<any>(`api/v1/rewards/log`);
  }

  getRewardsLogTotals(): Observable<any> {
    return this.http.get<any>(`api/v1/rewards/log/totals`);
  }

  getSchedulingRateHistory(): Observable<RateHistoryItem[]> {
    return this.http.get<RateHistoryItem[]>(`api/v1/rewards/rate-history-log`);
  }

  createSchedulingRateHistory(data) {
    return this.http.post<RateHistoryItem[]>(`api/v1/rewards/rate-history-log`, data);
  }

  deleteSchedulingRateHistory(id: string): Observable<any> {
    return <Observable<any>>(
      this.http.delete(`api/v1/rewards/rate-history-log/${id}`).map((response: any) => response.data)
    );
  }

  setPhoneNumber(id: string, data: { phone: string; }): Observable<User> {
    return this.http.post<any>(`api/v1/users/${id}/phone`, data).map((response: any) => response.data);
  }
}

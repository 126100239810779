import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isAppRole, User } from '../types/user';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UsersService } from './users.service';
import { environment } from '../../environments/environment';

@Injectable()
export class CurrentUserService {
  private store = sessionStorage;

  constructor(private http: HttpClient, private userService: UsersService) {}

  public getUser(): User {
    const user = this.store.getItem('upbookUser');
    return user ? JSON.parse(user) : null;
  }

  public getToken(): Observable<string> {
    const Auth = window['aws-amplify'].Auth;
    return from(Auth.currentSession()).pipe(
      map((session: any) => {
        return session.getAccessToken().getJwtToken();
      })
    );
  }

  public loadUser(): Observable<User> {
    return this.userService.getCurrentUser().pipe(
      tap(user => {
        if (isAppRole(user)) {
          this.logout();
        } else {
          this.store.setItem('upbookUser', JSON.stringify(user));
        }
      })
    );
  }

  public async logout() {
    this.store.removeItem('upbookUser');
    await this.destroySession().toPromise();
    const httpParams = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', environment.COGNITO_CLIENT_ID)
      .set('redirect_uri', `${environment.BASE_URL}/api/v1/auth/token/code`);
    window.location.href = `${environment.COGNITO_LOGIN_URL_DEFAULT}/login?${httpParams.toString()}`;
  }

  public async previewClientApp(companyId: string) {
    window.open(`${environment.CLIENT_URL}/app?company=${companyId}`);
  }

  public updatePassword(passwords: any): Observable<any> {
    const user = this.getUser();
    return <Observable<any>>(
      this.http.put<any>(`api/v1/users/${user.id}/password`, passwords).pipe(map(res => res.data))
    );
  }

  public checkSession(): Observable<any> {
    return of({success: true, message: "Session always valid"});
  }

  public destroySession(): Observable<any> {
    return <Observable<any>>this.http.get<any>(`api/v1/auth/token/logout`).pipe(map(res => res));
  }
}

import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../api/companies.service';
import { ActivatedRoute } from '@angular/router';
import { TableHeaderItem } from '../shared/table-base/table-header-item.interface';
import { UsersService } from '../api/users.service';
import { Toast } from '../shared/toast-message/toast';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { User } from '../types/user';

declare const $: any;

@Component({
  selector: 'up-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends BaseComponent implements OnInit {

  public companyId: string;
  public users: User[];
  public searchString: string;
  public selectedUser: User;
  public selectedCompanyId: string;
  public toast: Toast;
  public companyName: string;
  public header: TableHeaderItem[] = [
    {
      key: 'first_name',
      title: 'First name'
    },
    {
      key: 'last_name',
      title: 'Last name'
    },
    {
      key: 'email',
      title: 'Email'
    },
    {
      key: 'agentId',
      title: 'Agent ID'
    },
    {
      key: 'external_id',
      title: 'External ID'
    },
    {
      key: 'rewardsBalance',
      title: 'Rewards'
    },
    {
      key: 'role',
      title: 'Role'
    },
    {
      key: 'createdAtInitials',
      title: 'Consent'
    },
    {
      key: 'actions',
      title: 'Actions'
    }
  ];

  constructor(
    private companiesService: CompaniesService,
    private usersService: UsersService,
    private route: ActivatedRoute
  ) {
    super();
    this.route.params.subscribe((params: any) => {
      this.companyId = params.companyId;
      this.companyName = params.company_name;
    });
  }

  ngOnInit() {
    this.subs = this.companiesService
      .getCompanyUsers(this.companyId)
      .subscribe((users) => {
        this.users = users.map((user) => {
          user.rewardsBalance = '$' + parseFloat(user.rewardsBalance).toFixed(2);
          return user;
        });
      });
  }


  public editUser(user: User): void {
    this.selectedUser = { ...user };
    $('#user-dialog').modal('show');
    $('#user-dialog').on('hide.bs.modal', () => {
      this.selectedUser = null;
    });
  }

  public sendWelcomeEmail(user: User): void {
    this.selectedUser = user;
    $('#send-email-dialog').modal('show');
  }

  public sendEmail(): void {
    this.subs = this.usersService
      .sendEmail(this.selectedUser.id)
      .subscribe(
        () => {
          this.toast = {
            message: 'Email was sent.',
            type: 'success'
          };
        },
        () => {
          this.toast = {
            message: 'Cannot send email. Please try again.',
            type: 'danger'
          };
        });
  }


  public sendPINEmail(user: User): void {
    this.selectedUser = user;
    $('#send-pin-dialog').modal('show');
  }

  public sendPIN(): void {
    this.subs = this.usersService
      .sendPIN(this.selectedUser.id)
      .subscribe(
        () => {
          this.toast = {
            message: 'PIN code was sent.',
            type: 'success'
          };
        },
        () => {
          this.toast = {
            message: 'Cannot send PIN code. Please try again.',
            type: 'danger'
          };
        });
  }

  public showDeleteUserDialog(user: User) {
    this.selectedUser = user;
    $('#delete-user-dialog').modal('show');
  }

  public showDeleteUserRelationDialog(companyId: string, user: User) {
    this.selectedCompanyId = companyId;
    this.selectedUser = user;
    $('#delete-user-relation-dialog').modal('show');
  }

  public deleteUser() {
    this.subs = this.companiesService
      .deleteCompanyUser(this.companyId, this.selectedUser.id)
      .subscribe(() => {
        this.users = this.users.filter(user => user.id !== this.selectedUser.id);
        this.toast = {
          message: `User ${this.selectedUser.first_name} ${this.selectedUser.last_name} was successfully removed.`,
          type: 'success'
        };
        this.selectedUser = null;
      }, () => {
        this.toast = {
          message: 'Cannot remove the user. Please try again.',
          type: 'danger'
        };
      });
  }

  public deleteUserRelation(companyId, userId) {
    this.subs = this.companiesService
      .deleteCompanyUserRelation(companyId, userId)
      .subscribe(() => {
        this.users = this.users.map((user) => {
          user.companies = user.companies.filter(company => company.id !== companyId);
          return user;
        });
        this.toast = {
          message: `User ${this.selectedUser.first_name} ${this.selectedUser.last_name} was successfully removed.`,
          type: 'success'
        };
        this.selectedUser = null;
      }, () => {
        this.toast = {
          message: 'Cannot remove the user. Please try again.',
          type: 'danger'
        };
      });
  }

  public handleUserDialogSubmit(user) {
    if (this.selectedUser) {
      const existingUser = this.users.filter((i) => i.id === this.selectedUser.id)[0];
      Object.assign(existingUser, user);
      this.toast = {
        message: `User ${user.first_name} ${user.last_name} was successfully updated`,
        type: 'success'
      };
      this.selectedUser = null;
    } else {
      user.rewardsBalance = '$' + parseFloat(user.rewardsBalance).toFixed(2);
      this.users = [
        ...this.users,
        user
      ];
      this.toast = {
        message: `User ${user.first_name} ${user.last_name} was successfully created`,
        type: 'success'
      };
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { PaymentsService } from '../../api/payments.service';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';
import { BaseComponent } from '../base-component/base-component.component';
import { Toast } from '../toast-message/toast';
import { PaymentCompany } from '../../types/company';
import { ValidationService } from '../validators/validators.service';

@Component({
  selector: 'up-stripe',
  templateUrl: 'stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeTestComponent extends BaseComponent implements OnInit {
  @Input() company?: PaymentCompany;
  @Input() companyId: string;
  @Output() successCreate = new EventEmitter();

  public toast: Toast;
  public loading: boolean;
  public showCard: boolean;
  public showBank: boolean;
  public stripeCard: FormGroup;
  public stripeACH: FormGroup;
  private elements: Elements;
  private card: StripeElement;
  private elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private paymentsService: PaymentsService,
    private validatorService: ValidationService
  ) {
    super();
    this.createCardForm();
    this.createACHForm();
  }

  ngOnInit() {
    this.getInitData();
  }

  public submitForm(isCardForm?: boolean): void {
    if (isCardForm && this.stripeCard.invalid) {
      this.validatorService.markAllControlsAsTouched(this.stripeCard);
      return;
    } else if (!isCardForm && this.stripeACH.invalid) {
      this.validatorService.markAllControlsAsTouched(this.stripeACH);
      return;
    }
    this.loading = true;
    this.toast = null;
    const params = isCardForm ? this.stripeCard.value : this.stripeACH.value;
    const paymentData = isCardForm ? this.stripeCard.value : {
      country: this.stripeACH.get('address_country').value,
      currency: this.stripeACH.get('currency').value,
      routing_number: this.stripeACH.get('routing_number').value,
      account_number: this.stripeACH.get('account_number').value,
      account_holder_name: this.stripeACH.get('account_holder_name').value,
      account_holder_type: this.stripeACH.get('account_holder_type').value
    };
    const paymentType = isCardForm ? this.card : 'bank_account';
      this.subs = this.stripeService
      .createToken(paymentType, paymentData)
      .pipe(
        tap(result => {
          if (result.error) {
            this.toast = {
              message: result.error.message || 'Something goes bad. Please recheck all inputs.',
              type: 'danger'
            };
          }
        }),
        filter(result => {
          if (!result.token) {
            this.loading = false;
          }
          return !!result.token;
        }),
        switchMap(result => {
          params.id = result.token;
          if (isCardForm) {
            params.address_zip = result.token.card.address_zip;
          }
          return this.paymentsService.createCustomer(this.companyId, params);
        }),
        finalize(() => this.loading = false)
      )
      .subscribe(
        result => {
          this.successCreate.emit(result);
          const paymentMessage = isCardForm ? 'Credit card' : 'Bank Account';
          this.toast = {
            message: paymentMessage + ' successfully saved.',
            type: 'success'
          };
          if (isCardForm) {
            this.card.clear();
          }
        },
        error => {
          console.error(error);
          this.toast = {
            message: 'Something goes bad. Please recheck all inputs.',
            type: 'danger'
          };
        }
      );
  }

  public toggleType(type: string): void {
    if (type === 'card') {
      this.showBank = false;
      this.showCard = true;
      this.setCardForm();
    } else {
      this.showCard = false;
      this.showBank = true;
      this.setBankForm();
    }
  }

  private createCardForm(): void {
    this.stripeCard = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address_line1: ['', [Validators.required]],
      address_line2: '',
      address_city: ['', [Validators.required]],
      address_state: ['', [Validators.required]],
      address_country: ['', [Validators.required, Validators.maxLength(2)]]
    });
  }

  private createACHForm(): void {
    this.stripeACH = this.fb.group({
      bank_name: ['', [Validators.required]],
      address_country: ['', [Validators.required, Validators.maxLength(2)]],
      currency: ['', [Validators.required]],
      routing_number: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      account_holder_name: ['', [Validators.required]],
      account_holder_type: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address_line1: ['', [Validators.required]],
      address_line2: '',
      address_city: ['', [Validators.required]],
      address_state: ['', [Validators.required]],
    });
  }

  private getInitData(): void {
    this.toast = null;
    this.subs = this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });

    if (this.company && this.company.card) {
      this.showCard = true;
      this.setCardForm();
    } else if (this.company && this.company.bank) {
      this.showBank = true;
      this.setBankForm();
    } else {
      this.showCard = true;
    }
  }

  private setCardForm(): void {
    this.stripeACH.reset();
    this.stripeCard.patchValue(this.company || {});
    if (this.company && this.company.card) {
      this.stripeCard.controls['name'].setValue(this.company.card.name || '');
    }
  }

  private setBankForm(): void {
    this.stripeCard.reset();
    this.stripeACH.patchValue(this.company || {});
    if (this.company && this.company.bank) {
      this.stripeACH.controls['bank_name'].setValue(this.company.bank.bank_name || '');
      this.stripeACH.controls['currency'].setValue(this.company.bank.currency || '');
      this.stripeACH.controls['routing_number'].setValue(this.company.bank.routing_number || '');
      this.stripeACH.controls['account_holder_name'].setValue(this.company.bank.account_holder_name || '');
      this.stripeACH.controls['account_holder_type'].setValue(this.company.bank.account_holder_type || '');
      this.stripeACH.controls['account_number'].setValue(`****** ${this.company.bank.last4}` || '');
    }
  }
}

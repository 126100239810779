import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toast } from '../shared/toast-message/toast';
import { RateHistoryItem } from '../types/reward';
import { TableHeaderItem } from '../shared/table-base/table-header-item.interface';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { UsersService } from '../api/users.service';
import { ManualRateHistoryModalComponent } from './manual-rate-history-modal/manual-rate-history-modal.component';

interface RateHistoryHeaderItem extends TableHeaderItem {
  key: keyof RateHistoryItem | 'actions';
}

declare let $: any;

@Component({
  selector: 'up-rewards-log',
  templateUrl: './rate-history.component.html',
  styleUrls: ['./rate-history.component.scss']
})
export class RateHistoryComponent extends BaseComponent implements OnInit {
  public loading = false;
  public logs: RateHistoryItem[] = [];
  public toast: Toast;
  public header: RateHistoryHeaderItem[] = [
    { key: 'createdAt', title: 'Date'},
    { key: 'companyName', title: 'Company' },
    { key: 'teamActualSchedulingRate', title: 'Actual scheduling rate' },
    { key: 'targetSchedulingRate', title: 'Target scheduling rate' },
    { key: 'bookedCalls', title: 'Booked calls' },
    { key: 'notBookedCalls', title: 'Not booked calls' },
    { key: 'rebookedCalls', title: 'Rebooked calls' },
    { key: 'flaggedCalls', title: 'Flagged calls'},
    { key: 'rebookingOpportunities', title: 'Rebooking opportunities' },
    { key: 'members', title: 'Members' },
    { key: 'actions', title: 'Actions' }
  ];
  public searchString = '';
  public modalData: RateHistoryItem | null = null;

  constructor(
    private usersService: UsersService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.subs = this.usersService.getSchedulingRateHistory()
      .pipe(finalize(() => this.loading = false))
      .subscribe(result => this.logs = result);
  }

  onRowClick(item: RateHistoryItem) {
    this.modalData = item;
    $('#rate-history-members-modal').modal('show');
  }

  public createManualEntry() {
    const modalRef = this.modalService.open(ManualRateHistoryModalComponent);
    modalRef.result.then(result => {
      const item: RateHistoryItem = result;
      this.logs = [
        item,
        ...this.logs
      ];
      this.toast = {
        message: `Item was successfully created`,
        type: 'success'
      };
    }, () => {});
  }

  public deleteManualEntry(data) {
    this.subs = this.usersService.deleteSchedulingRateHistory(data.id)
      .subscribe(result => {
        if (result) {
          this.logs = this.logs.filter(item => item.id !== data.id);
        }
      });
  }
}

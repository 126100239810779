import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { ActivatedRoute} from '@angular/router';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'up-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent extends BaseComponent implements OnInit {
  DEFAULT_TAB = 'day';
  @ViewChild('tabs') tabSet: NgbTabset;
  tab: string;
  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.tab = this.DEFAULT_TAB;
    this.route.params.subscribe(params => {
      this.tab = params.tab || this.DEFAULT_TAB;
      this.tabSet.select(this.tab);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RateHistoryComponent } from './rate-history.component';
import { SharedModule } from '../shared/shared.module';
import { RateHistoryMembersModalComponent } from './rate-history-members-modal/rate-history-members-modal.component';
import { ManualRateHistoryModalComponent } from './manual-rate-history-modal/manual-rate-history-modal.component';
import { CompanySelectComponent} from './manual-rate-history-modal/company-select/company-select.component';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: '',
    component: RateHistoryComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NguiAutoCompleteModule
  ],
  declarations: [
    RateHistoryComponent,
    RateHistoryMembersModalComponent,
    ManualRateHistoryModalComponent,
    CompanySelectComponent
  ],
  entryComponents: [
    RateHistoryMembersModalComponent,
    ManualRateHistoryModalComponent
  ]
})
export class RateHistoryModule { }

export interface User {
  rewardsBalance: string;
  createdAt?: string;
  createdAtInitials: string;
  email: string;
  first_name: string;
  id: string;
  agentId?: string;
  initials: string;
  isFirstLogin?: boolean;
  last_name: string;
  role: string;
  updatedAt?: string;
  username?: string;
  external_id: any;
  companies?: any;
  phone?: string;
  hasCognitoPhoneNumber?: boolean;
  manageInvoices?: boolean
}

export function isAppRole(user: User): boolean {
  return ['owner', 'manager', 'member'].includes(user.role);
}

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CurrentUserService } from '../api/current-user.service';

@Injectable()
export class ReviewerGuard implements CanActivate {

  constructor(private router: Router,
              private currentUserService: CurrentUserService) { }

  canActivate() {
    const currentUser = this.currentUserService.getUser();
    if (currentUser && currentUser.role === 'call_reviewer') {
      this.router.navigate(['review-calls']);
      return false;
    }
    return true;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toast } from '../../shared/toast-message/toast';
import { ValidationService } from '../../shared/validators/validators.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompaniesService } from '../../api/companies.service';
import { UsersService } from '../../api/users.service';
import * as moment from 'moment';
import { Company } from '../../types/company';
import { RateHistoryItem } from '../../types/reward';

@Component({
  selector: 'up-manual-rate-history-modal',
  templateUrl: './manual-rate-history-modal.component.html',
  styleUrls: ['./manual-rate-history-modal.component.scss']
})
export class ManualRateHistoryModalComponent extends BaseComponent implements OnInit {
  @Input() id: string;
  @Input() data: RateHistoryItem | null;
  @Output() saveData = new EventEmitter();

  public rateHistoryForm: FormGroup;
  public toast: Toast;
  public loading: boolean;
  public createdAt: any;
  public companies: Company[];
  private selectedCompany: Company;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private usersService: UsersService,
    private companiesService: CompaniesService,
    public activeModal: NgbActiveModal,
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    if (this.data) {
      const formValue = { ...this.data };
      this.rateHistoryForm.patchValue(formValue);
    }
    this.subs = this.companiesService.getCompanies()
      .subscribe(
        (data: Company[]) => {
          this.companies = data;
        },
        () => {
          this.toast = {
            message: 'Error loading, please try again.',
            type: 'danger'
          };
          this.loading = false;
        });
  }

  private createForm(): void {
    this.rateHistoryForm = this.fb.group({
      bookedCalls: ['', [Validators.required]],
    });
  }

  public saveForm(): void {
    this.toast = null;
    if (this.rateHistoryForm.value && !this.rateHistoryForm.value.bookedCalls) {
      this.toast = {
        message: 'Please enter number of booked calls.',
        type: 'danger'
      };
      return;
    }
    const saveForm = {
      companyId: this.selectedCompany.id,
      companyName: this.selectedCompany.name,
      createdAt: moment().year(this.createdAt.year).month(this.createdAt.month - 1).date(this.createdAt.day).hour(23).minute(59).second(59),
      bookedCalls: parseInt(this.rateHistoryForm.value.bookedCalls, 10),
    };

    this.activeModal.close(saveForm);
    if (!this.rateHistoryForm.valid) {
      this.validationService.markAllControlsAsTouched(this.rateHistoryForm);
      return;
    }
    this.loading = true;
    const request = this.usersService.createSchedulingRateHistory(saveForm);
    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (result) => {
          this.activeModal.close(result);
        },
        (err) => {
          const msg = `Cannot ${saveForm ? 'modify' : 'create'} the scheduling history item. Please try again.`;
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });

  }

  public onCompanySelect(company: Company | null): void {
    if (company) {
      this.selectedCompany = company;
    }
  }

}

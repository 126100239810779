import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from '../../shared/base-component/base-component.component';
import { User } from '../../types/user';
import { Toast } from '../../shared/toast-message/toast';
import { ValidationService } from '../../shared/validators/validators.service';
import { UsersService } from '../../api/users.service';
import { CurrentUserService } from '../../api/current-user.service';

@Component({
  selector: 'up-phone-number-dialog',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberDialog extends BaseComponent implements OnInit {
  @Input() user?: User;

  public toast: Toast;
  public loading: boolean;
  public phoneNumberForm: FormGroup;
  public isFormSubmitted: boolean;
  public phoneNumber;

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    public activeModal: NgbActiveModal,
    private usersService: UsersService,
    private currentUser: CurrentUserService
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.setForm();
  }

  public submitPhoneNumberForm(): void {
    if (!this.phoneNumberForm.valid) {
      this.isFormSubmitted = true;
      // this.validationService.markAllControlsAsTouched(this.phoneNumberForm);
      return;
    }
    this.loading = true;
    let request;
    if (this.user) {
      request = this.usersService.setPhoneNumber(this.user.id, this.phoneNumberForm.value);
    } 

    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (result) => {
          this.activeModal.close(result);
        },
        (err) => {
          let msg;
          msg = `Cannot ${this.user ? 'modify' : 'create'} the user phone number. Please try again.`;
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });
  }

  private createForm() {
    this.phoneNumberForm = this.formBuilder.group({
      phone: ['', [
        Validators.required
      ]],
    });
  }

  private setForm() {
    this.phoneNumberForm.patchValue(this.user || {});
  }
  
  public logout(): void {
    this.currentUser.logout();
  }
}


import { Component, OnInit, OnChanges, Input, ViewChild, SimpleChanges } from '@angular/core';
import { environment } from '../../../environments/environment';

declare const $: any;

@Component({
  selector: 'up-site-code-dialog',
  templateUrl: './site-code-dialog.component.html',
  styleUrls: ['./site-code-dialog.component.scss']
})
export class SiteCodeDialogComponent implements OnInit, OnChanges {
  @Input() id;
  @Input() company;

  @ViewChild('textarea') textarea;

  public scriptCode = '';

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes): void {
    if (changes.company && changes.company.currentValue) {
      const selectedCompany = changes.company.currentValue;
      const { callForwardingTrackingPhone = '', phone = '' } = selectedCompany;
      const phoneDigits = phone.match( /\d+/g ).join('');
      const phoneSplit = [
        phoneDigits.substring(0, 3),
        phoneDigits.substring(3, 6),
        phoneDigits.substring(6)];
      this.scriptCode = `<script type="text/javascript">
window.addEventListener('DOMContentLoaded', () => {
  var elements = document.body.getElementsByTagName("*");
  for (var i = 0; i < elements.length; i++) {
    var result = elements[i].innerHTML.replace(/\\(?${phoneSplit[0]}\\)?[- ]${phoneSplit[1]}[- ]${phoneSplit[2]}/, '${callForwardingTrackingPhone}');
    elements[i].innerHTML = result;
  }
});
</script>`;
    }
  }

  public copyScript(): void {
    $(this.textarea.nativeElement).select();
    document.execCommand('copy');
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Company } from '../types/company';
import { User } from '../types/user';
import * as moment from 'moment-timezone';
import { environment } from '../../environments/environment';

const baseUrl = 'api/v1/companies';
const baseCompanyUsersUrl = 'api/v1';

@Injectable()
export class CompaniesService {

  constructor(private http: HttpClient) { }

  static isNewCompany(company) {
    const dStart = moment(company.createdAt);
    const dEnd = moment(Date.now());
    return dEnd.diff(dStart, 'days') <= environment.COMPANY_AGE;
  }

  getCompany(id: string): Observable<any> {
    return this.http.get<any>(`${baseUrl}/${id}`).pipe(map(res => res.data));
  }

  getStats(id: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        time_zone: moment.tz.guess()
      }
    });
    return this.http.get<any>(`${baseUrl}/${id}/stats`, { params }).pipe(map(res => res.data));
  }

  getCompanies(): Observable<Company[]> {
    return <Observable<Company[]>> this.http.get(baseUrl).map((response: any) => response.data);
  }

  getKazooCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(baseUrl)
      .map((response: any) => response.data.filter(company => !!company.kazoo_external_id));
  }

  createCompany(data: Company): Observable<Company> {
    return <Observable<Company>> this.http.post(baseUrl, data).map((response: any) => response.data);
  }

  editCompany(id: string, data: Company): Observable<Company> {
    return <Observable<Company>> this.http.put(`${baseUrl}/${id}`, data).map((response: any) => response.data);
  }

  deleteCompany(id: string): Observable<any> {
    return <Observable<any>> this.http.delete(`${baseUrl}/${id}`).map((response: any) => response.data);
  }

  publishKeyless(id: string): Observable<any> {
    return <Observable<any>> this.http.get(`${baseUrl}/${id}/publish-keyless`).map((response: any) => response.data);
  }

  processRewardsPeriod(id: string): Observable<any> {
    return <Observable<any>> this.http.get(`${baseCompanyUsersUrl}/rewards/process?companyId=${id}&overrideTarget=true`)
      .map((response: any) => response);
  }

  getCompanyUsers(id: string): Observable<User[]> {
    return <Observable<User[]>> this.http.get(`api/v1/users?company_id=${id}&checkExternalIds=true`)
      .map((response: any) => {
        return response.data
      });
  }

  editCompanyUser(companyId: string, userId: string, user): Observable<User> {
    return <Observable<User>> this.http.put(`${baseUrl}/${companyId}/users/${userId}`, user)
      .map((response: any) => response.data);
  }

  deleteCompanyUser(companyId: string, userId: string) {
    return this.http.delete(`${baseUrl}/${companyId}/users/${userId}`)
      .map((response: any) => response.data);
  }

  deleteCompanyUserRelation(companyId: string, userId: string) {
    return this.http.delete(`${baseUrl}/${companyId}/users/${userId}?removeRelationOnly=true`)
      .map((response: any) => response.data);
  }

  getFeaturesList(): Observable<any> {
    return <Observable<any>> this.http.get(`${baseCompanyUsersUrl}/possible-features`).map((response: any) => response.data);
  }

  updateAllCompanies(data): Observable<any> {
    return <Observable<any>> this.http.post(`${baseUrl}/updateAll`, data).map((response: any) => response.data);
  }
}

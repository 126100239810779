import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '../../types/company';

@Component({
  selector: 'up-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent {
  @Input() searchData: Company[];
  @Output() selectedCompany: EventEmitter<Company | null> = new EventEmitter<Company>();
  public companyData: Company;
  public disabledText: Boolean;

  public onSelected(item: Company | any): void {
    if (item) {
      this.searchData.forEach(m => {
        if ((m.id === item.id)) {
          this.disabledText = true;
          this.selectedCompany.emit(item);
        }
      });
    } else {
      this.disabledText = false;
      this.companyData = null;
      this.selectedCompany.emit(null);
    }
  }
}

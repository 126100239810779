import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { TableHeaderItem } from './table-header-item.interface';
import { PAGINATION_SIZE, PAGE_SIZES } from '../../app.constants';

declare const $: any;

interface Pagination {
  current: number;
  total: number;
  pages: number[];
}

/**
 * @Component TableBaseComponent
 *
 * @Input { Object[] } headerData - array of header cells. See TableHeaderItem Interface
 * @Input { Object[] } tableData - array of table rows. Data cell value is determined by the corresponding header.key value
 * @Input { TemplateRef } cellTemplate - custom template for data cell. It is used for all data cells. If there are
 *                                       multiple custom cells in one row use ngSwitch directive.
 *
 * @Output {EventEmitter<any>} onClick - emit row data on row click
 */

@Component({
  selector: 'up-table-base',
  templateUrl: './table-base.component.html',
  styleUrls: ['./table-base.component.scss']
})
export class TableBaseComponent implements OnInit, OnChanges {
  @Input() headerData: TableHeaderItem[];
  @Input() tableData: any[];
  @Input() fullRow = false;
  @Input() cellTemplate: TemplateRef<any>;
  @Output() rowClick = new EventEmitter();

  @ViewChild('pageSizeSelect') pageSizeSelect;

  public rows: any[];
  public pageSizes = PAGE_SIZES;
  public currentPageSize = PAGE_SIZES[1];
  public pagination: Pagination = {
    pages: []
  } as Pagination;

  ngOnInit() {
    $(this.pageSizeSelect.nativeElement).selectpicker({
      width: '80px'
    });

    $(this.pageSizeSelect.nativeElement).on('changed.bs.select', () => {
      this.setPageSize(+$(this.pageSizeSelect.nativeElement).val());
    });

    if (this.tableData && this.headerData) {
      this.initTable();
      this.initPagination();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.tableData &&
      changes.tableData.previousValue &&
      changes.tableData.previousValue.length !== changes.tableData.currentValue.length
    ) {
      this.initTable();
      this.initPagination();
      this.retainSort();
    }
  }

  private initTable(): void {
    this.rows = [...this.tableData];
  }

  private initPagination(): void {
    this.pagination.total = Math.ceil(this.rows.length / this.currentPageSize);
    this.pagination.current = 0;
    this.setPage(1);
  }

  private retainSort(): void {
    const sortItemData = this.headerData.filter(item => !!item.sortDirection);
    if (sortItemData.length <= 0) {
      return;
    }
    const headerItem: TableHeaderItem = sortItemData[0] as TableHeaderItem;
    const key = headerItem.key;
    const direction = headerItem.sortDirection;
    this.rows.sort((itemA, itemB) => {
      let a, b;

      a = itemA[key] || '';
      b = itemB[key] || '';
      if (key === 'createdAt') {
        a = new Date(a).getTime();
        b = new Date(b).getTime();
      }

      if (a < b) {
        return -1 * direction;
      }
      if (a > b) {
        return direction;
      }
      return 0;
    });
  }

  public setPage(page: number): void {
    if (
      page < 1 ||
      page > this.pagination.total ||
      (page === 1 && this.pagination.current === 1) ||
      (page === this.pagination.total && this.pagination.current === this.pagination.total)
    ) {
      return;
    }

    this.pagination.current = page;
    this.pagination.pages = [];
    let start = this.pagination.current - Math.floor(this.currentPageSize / 2);
    let end = start + this.currentPageSize - 1;

    if (start < 1) {
      const delta = 1 - start;
      start += delta;
      end += delta;
    }

    if (end > this.pagination.total) {
      const delta = this.pagination.total - end;
      start += delta;
      end += delta;

      if (start < 1) {
        start = 1;
      }
    }

    for (let i = start, j = 0; i <= end; i++, j++) {
      this.pagination.pages[j] = i;
    }
  }

  public getRowsOffset(pageOffset: number = 0): number {
    return (this.pagination.current + pageOffset) * this.currentPageSize;
  }

  public sort(headerItem: TableHeaderItem): void {
    this.headerData.forEach(header => {
      if (header !== headerItem) {
        header.sortDirection = 0;
      }
    });

    if (!headerItem.sortDirection) {
      headerItem.sortDirection = 1;
    } else if (headerItem.sortDirection === 1) {
      headerItem.sortDirection = -1;
    } else if (headerItem.sortDirection == -1) {
      headerItem.sortDirection = 1;
    } else {
      headerItem.sortDirection = 0;
      return;
    }

    const key = headerItem.key;
    const direction = headerItem.sortDirection;
    this.rows.sort((itemA, itemB) => {
      let a, b;

      a = itemA[key] || '';
      b = itemB[key] || '';
      if (key === 'createdAt') {
        a = new Date(a).getTime();
        b = new Date(b).getTime();
      }

      if (a < b) {
        return -1 * direction;
      }
      if (a > b) {
        return direction;
      }
      return 0;
    });
  }

  public onRowClick(row: any): void {
    this.rowClick.emit(row);
  }

  public getStartItemNumber() {
    if (!this.rows.length) {
      return 0;
    }
    return (this.pagination.current - 1) * this.currentPageSize + 1;
  }

  public getEndItemNumber() {
    if (this.rows.length < this.currentPageSize) {
      return this.rows.length;
    }
    return this.getStartItemNumber() + this.currentPageSize;
  }

  public setPageSize(size: number) {
    this.currentPageSize = size;
    this.initPagination();
  }
}

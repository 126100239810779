export const environment = {
  production: false,
  BASE_URL: 'https://api-public.staging.upbook.com',
  CLIENT_URL: 'https://app.staging.upbook.com',
  COMPANY_AGE: 21, // Days
  COGNITO_CLIENT_ID: '14kk2jpilh3c0sr0k14uitoi3j',
  COGNITO_USER_POOL_ID: 'ca-central-1_xH1d3akMD',
  COGNITO_LOGIN_URL_DEFAULT: 'https://login-upbook-staging.auth.ca-central-1.amazoncognito.com',
  AWS_REGION: 'ca-central-1',
  ENABLE_MFA: false,
};

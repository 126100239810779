import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from '../api/current-user.service';
import { User } from '../types/user';
import { PhoneNumberDialog } from './phone-number-dialog/phone-number.component';
import { environment } from '../../environments/environment';

import 'rxjs/add/operator/filter';

@Component({
  selector: 'up-app-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.scss']
})
export class MainComponent implements OnInit {
  public title: string;
  public user: User;
  public modalOption: NgbModalOptions = {};;

  constructor(private currentUser: CurrentUserService, 
    private modalService: NgbModal) {
      this.user = this.currentUser.getUser();
  }

  ngOnInit() {
    if(!this.user.hasCognitoPhoneNumber && environment.ENABLE_MFA) {
      this.openPhoneNumberModal()
    }
  }

  private openPhoneNumberModal(): void {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(PhoneNumberDialog, this.modalOption);
    modalRef.componentInstance.user = this.user;
    modalRef.result.then(result => {
      this.handleUserDialogSubmit(result);
    }, () => {});
  }

  private handleUserDialogSubmit(user: User): void {

  }

}

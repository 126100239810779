import { Component, OnInit } from '@angular/core';
import { TableHeaderItem } from '../shared/table-base/table-header-item.interface';
import { CompaniesService } from '../api/companies.service';
import { CallsService } from '../api/calls.service';
import { Router } from '@angular/router';
import { CurrentUserService } from '../api/current-user.service';
import { environment } from '../../environments/environment';
import { Company } from '../types/company';
import { Toast } from '../shared/toast-message/toast';

import 'object-assign';
import { BaseComponent } from '../shared/base-component/base-component.component';

declare let $: any;

const clientUrl = environment.CLIENT_URL;

@Component({
  selector: 'up-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent extends BaseComponent implements OnInit {
  public header: TableHeaderItem[] = [
    {
      key: 'name',
      title: 'Company name'
    },
    {
      key: 'hipaa',
      title: 'HIPAA compliant'
    },
    {
      key: 'isShowFullBoard',
      title: 'Full Dashboard'
    },
    {
      key: 'actions',
      title: 'Actions'
    }
  ];

  public toast: Toast;
  public companies = [];
  public searchString = '';
  public selectedCompany: any;
  public selectedCompanyCallsOnReview: any;
  public currentUser: any;

  constructor(
    private companiesService: CompaniesService,
    private currentUserService: CurrentUserService,
    private callsService: CallsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = this.currentUserService.getUser();
    this.subs = this.companiesService.getCompanies().subscribe((data: Company[]) => {
      this.companies = data
        .map(company => ({
          ...company,
          isNewCompany: CompaniesService.isNewCompany(company)
        }))
        .sort((left, right) => new Date(right.createdAt).getTime() - new Date(left.createdAt).getTime());
    });
  }

  public toggleHippa(company: Company): void {
    company.hipaaLoading = true;
    this.subs = this.companiesService
      .editCompany(company.id, company)
      .finally(() => (company.hipaaLoading = false))
      .subscribe(
        () => {},
        () => {
          this.toast = {
            message: 'Cannot modify the company. Please try again.',
            type: 'danger'
          };
        }
      );
  }

  public toggleFullBoard(company: Company): void {
    company.fullBoardLoading = true;
    this.subs = this.companiesService
      .editCompany(company.id, company)
      .finally(() => (company.fullBoardLoading = false))
      .subscribe(
        () => {},
        () => {
          this.toast = {
            message: 'Cannot modify the company. Please try again.',
            type: 'danger'
          };
        }
      );
  }

  public previewCompany(company: Company): void {
    this.currentUserService.previewClientApp(company.id);
  }

  public showCompanyUsers(company: Company): void {
    this.router.navigate(['/companies', company.id, { company_name: company.name }]);
  }

  public editCompany(company: Company): void {
    this.selectedCompany = { ...company };
    $('#company-dialog').modal('show');
    $('#company-dialog').on('hide.bs.modal', () => {
      this.selectedCompany = null;
    });
  }

  public showSiteCode(company: Company): void {
    this.selectedCompany = company;
    $('#site-code-dialog').modal('show');
    $('#site-code-dialog').on('hide.bs.modal', () => {
      this.selectedCompany = null;
    });
  }

  public showDeleteCompanyDialog(company: Company): void {
    this.selectedCompany = company;
    if (this.selectedCompany.kazoo_external_id) {
      this.subs = this.callsService.getCallsCountByCompany(this.selectedCompany.kazoo_external_id).subscribe(
        data => {
          const { callsLeftToReviewCount = 0 } = data;
          if (callsLeftToReviewCount) {
            this.selectedCompanyCallsOnReview = data;
            $('#delete-company-dialog-with-calls-on-review').modal('show');
          } else {
            $('#delete-company-dialog').modal('show');
          }
        },
        () => {
          this.toast = {
            message: 'An error occurred when trying to provision the calls on review for this company.',
            type: 'danger'
          };
        }
      );
    } else {
      $('#delete-company-dialog').modal('show');
    }

  }

  public handleCompanyDialogSubmit(company: Company): void {
    const existingCompany = this.companies.filter(i => i.id === company.id)[0];
    if (existingCompany) {
      Object.assign(existingCompany, company);
      this.toast = {
        message: `Company was successfully updated`,
        type: 'success'
      };
    } else {
      this.companies = [company, ...this.companies];
      this.toast = {
        message: `Company ${company.name} was successfully created`,
        type: 'success'
      };
    }
  }

  public handleFeaturesDialogSubmit(companies): void {
    this.companies = companies;
    this.toast = {
      message: `All Companies Features Updated`,
      type: 'success'
    };
  }
  public publishKeyless(company: Company): void {
    this.selectedCompany = company;
    if (!this.selectedCompany.kazoo_external_id) {
      this.toast = {
        message: 'A valid Kazoo Account ID is required to provision phone trees.',
        type: 'danger'
      };
      return;
    }
    this.subs = this.companiesService.publishKeyless(this.selectedCompany.id).subscribe(
      data => {
        if (data) {
          this.toast = {
            message: `The Keyless Phone Tree for ${this.selectedCompany.name} was successfully provisioned.`,
            type: 'success'
          };
        } else {
          this.toast = {
            // tslint:disable-next-line:max-line-length
            message: `The Keyless Phone Tree for ${this.selectedCompany.name} already exists. <br />To reset, remove pivot and extension callflows in Kazoo and try again.`,
            type: 'danger'
          };
        }
        this.selectedCompany = null;
      },
      () => {
        this.toast = {
          message: 'An error occurred when trying to provision the phone tree for this company.',
          type: 'danger'
        };
      }
    );
  }

  public processRewardsPeriod(company: Company): void {
    this.selectedCompany = company;
    if (!this.selectedCompany) {
      this.toast = {
        message: 'A valid company is required to process the rewards period manually.',
        type: 'danger'
      };
      return;
    }
    this.toast = {
      message: `Processing Rewards Period manually for ${this.selectedCompany.name}... Please wait...`,
      type: 'success'
    };
    this.subs = this.companiesService.processRewardsPeriod(this.selectedCompany.id).subscribe(
      data => {
        if (data) {
          this.toast = {
            // tslint:disable-next-line:max-line-length
            message: `The Rewards Period for ${
              this.selectedCompany.name
            } was successfully processed. <pre>${JSON.stringify(data, null, 2)}</pre>`,
            type: 'success'
          };
          this.selectedCompany = null;
        } else {
          this.toast = {
            message: 'There was an error processing this Rewards Period manually, please try again.',
            type: 'danger'
          };
        }
      },
      () => {
        this.toast = {
          message: 'There was an error processing this Rewards Period manually, please try again.',
          type: 'danger'
        };
      }
    );
  }

  public deleteCompany(): void {
    this.subs = this.companiesService.deleteCompany(this.selectedCompany.id).subscribe(
      () => {
        this.companies = this.companies.filter(c => c.id !== this.selectedCompany.id);
        this.toast = {
          message: `Company ${this.selectedCompany.name} was successfully deleted`,
          type: 'success'
        };
        this.selectedCompany = null;
      },
      () => {
        this.toast = {
          message: 'Cannot remove the company. Please try again.',
          type: 'danger'
        };
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './main.component';
import { CompaniesModule } from '../companies/companies.module';
import { AuthGuard } from './auth.guard';
import { ProfileModule } from '../profile/profile.module';
import { SupportManagersModule } from '../support-managers/support-managers.module';
import { ReviewCallsModule } from '../review-calls/review-calls.module';
import { CallReviewersModule } from '../call-reviewers/call-reviewers.module';
import { ReviewerGuard } from './reviewer.guard';
import { RewardsModule } from '../rewards/rewards.module';
import { RateHistoryModule } from '../rate-history/rate-history.module';
import { RewardsLogModule } from '../events/rewards-log.module';

export function getCompaniesModule() {
  return CompaniesModule;
}

export function getProfileModule() {
  return ProfileModule;
}

export function getSupportModule() {
  return SupportManagersModule;
}

export function getReviewCallsModule() {
  return ReviewCallsModule;
}

export function getCallReviewersModule() {
  return CallReviewersModule;
}

export function getRewardsModule() {
  return RewardsModule;
}

export function getRateHistoryModule() {
  return RateHistoryModule;
}

export function getRewardsLogsModule() {
  return RewardsLogModule;
}

const mainRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [ReviewerGuard],
        loadChildren: getCompaniesModule
      },
      {
        path: 'profile',
        loadChildren: getProfileModule
      },
      {
        path: 'support-managers',
        canActivate: [ReviewerGuard],
        loadChildren: getSupportModule
      },
      {
        path: 'review-calls',
        loadChildren: getReviewCallsModule
      },
      {
        path: 'call-reviewers',
        canActivate: [ReviewerGuard],
        loadChildren: getCallReviewersModule
      },
      {
        path: 'rewards',
        canActivate: [ReviewerGuard],
        loadChildren: getRewardsModule
      },
      {
        path: 'rate-history',
        loadChildren: getRateHistoryModule
      },
      {
        path: 'rewards-log',
        loadChildren: getRewardsLogsModule
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(mainRoutes)],
  providers: [AuthGuard, ReviewerGuard]
})
export class MainRoutingModule {}

import { Component, Input, OnInit, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsersService } from '../../api/users.service';
import { ValidationService } from '../../shared/validators/validators.service';
import { SupportManagersService } from '../../api/supportManagers.service';
import { Toast } from '../../shared/toast-message/toast';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { User } from '../../types/user';

declare const $: any;

@Component({
  selector: 'up-support-dialog',
  templateUrl: './support-manager-dialog.component.html',
  styleUrls: []
})
export class SupportManagersDialogComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() id: string;
  @Input() user?: User;

  @Output() submit = new EventEmitter();

  public toast: Toast;
  public loading: boolean;
  public userForm: FormGroup;
  public isFormSubmitted: boolean;

  constructor(private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private usersService: UsersService,
              private supportManagersService: SupportManagersService) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.user = null;
  }

  ngAfterViewInit() {
    $('#user-dialog').on('hide.bs.modal', () => {
      this.toast = null;
      this.resetForm();
    });
  }

  ngOnChanges(changes) {
    if (changes.user) {
      this.resetForm();
      this.user = changes.user.currentValue;
      this.setForm();
    }
  }

  private createForm() {
    this.userForm = this.formBuilder.group({
      first_name: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]],
      last_name: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]]
    });
  }

  private setForm() {
    this.userForm.patchValue(this.user || {});
  }

  private resetForm() {
    this.user = null;
    this.userForm.reset({});
  }

  public submitUserForm() {
    if (!this.userForm.valid) {
      this.isFormSubmitted = true;
      this.validationService.markAllControlsAsTouched(this.userForm);
      return;
    }
    this.loading = true;
    let request;
    if (this.user) {
      request = this.supportManagersService.update(this.userForm.value, this.user.id);
    } else {
      const params = this.userForm.value;
      params.role = 'support';
      request = this.supportManagersService.create(params);
    }
    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (res) => {
          this.submit.emit(res);
          $(`#${this.id}`).modal('hide');
        },
        (err) => {
          let msg;
          if (err && err.status === 409) {
            if (err.error.error.email) {
              msg = 'User with this email already exists.';
            }
          } else {
            if (err && err.error.error.first_name) {
              msg = `Valid first name should be provided`;
            } else if (err && err.error.error.last_name) {
              msg = `Valid last name should be provided`;
            } else {
              msg = `Cannot ${this.user ? 'modify' : 'create'} the user. Please try again.`;
            }
          }
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });
  }
}

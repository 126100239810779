import { Component, Input, OnInit, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsersService } from '../../api/users.service';
import { ValidationService } from '../../shared/validators/validators.service';
import { CompaniesService } from '../../api/companies.service';
import { Toast } from '../../shared/toast-message/toast';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { User } from '../../types/user';
import * as uuid from 'uuid/v4';
import 'rxjs-compat/add/operator/debounceTime';

declare const $: any;

@Component({
  selector: 'up-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() id: string;
  @Input() user?: User;
  @Input() companyId: string;

  @Output() submit = new EventEmitter();

  public toast: Toast;
  public loading: boolean;
  public userForm: FormGroup;
  public isFormSubmitted: boolean;
  public prevExternalId: string;

  constructor(private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private usersService: UsersService,
              private companiesService: CompaniesService) {
    super();
    this.createForm();
  }

  public ngOnInit() {
    this.user = null;
    this.initUser();
    this.setForm();
  }

  public ngAfterViewInit() {
    $('#user-dialog').on('hide.bs.modal', () => {
      this.toast = null;
      this.resetForm();
    });
    this.userForm.controls['external_id'].valueChanges.debounceTime(1000).subscribe((value) => {
      if (value === '') {
        if (!this.user) {
          this.initUser();
          this.setForm();
        } else {
          this.user.external_id = uuid();
          this.setForm();
        }
      }
    });
  }

  public ngOnChanges(changes) {
    if (changes.user) {
      this.resetForm();
      this.user = changes.user.currentValue;
      this.initUser();
      this.setForm();
    }
  }

  private initUser() {
    if (this.user) {
      this.prevExternalId = this.user.external_id;
      return;
    }
    this.user = {
      createdAtInitials: null,
      email: null,
      external_id: uuid(),
      first_name: null,
      id: null,
      initials: null,
      last_name: null,
      agentId: null,
      phone: null,
      rewardsBalance: null,
      role: null,
      manageInvoices: false
    };
  }

  private createForm() {
    this.userForm = this.formBuilder.group({
      first_name: ['', [this.validationService.noWhiteSpace]],
      last_name: ['', [this.validationService.noWhiteSpace]],
      agentId: ['', []],
      phone: ['', []],
      username: '',
      email: ['', [
        Validators.email
      ]],
      external_id: ['', [
        Validators.required,
        this.validationService.noWhiteSpace
      ]],
      role: [null, [
        Validators.required
      ]],
      manageInvoices: ['', []]
    });
  }

  private setForm() {
    this.userForm.patchValue(this.user || {});
  }

  private resetForm() {
    this.user = null;
    this.userForm.reset({});
    this.initUser();
    this.setForm();
  }

  public submitUserForm() {
    if (!this.userForm.valid) {
      this.isFormSubmitted = true;
      this.validationService.markAllControlsAsTouched(this.userForm);
      return;
    }
    this.toast = null;
    this.loading = true;
    let request;
    if (this.user && this.user.id) {
      const params = this.userForm.value;
      params.prev_external_id = this.prevExternalId;
      request = this.companiesService.editCompanyUser(this.companyId, this.user.id, this.userForm.value);
    } else {
      const params = this.userForm.value;
      params._company = this.companyId;
      params.username = uuid();
      if (!params.agentId) {
        delete params.agentId;
      }
      request = this.usersService.createUser(params);
    }
    this.resetForm();
    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (res) => {
          this.submit.emit(res);
          $(`#${this.id}`).modal('hide');
        },
        (err) => {
          let msg;
          if (err && err.status === 409) {
            if (err.error.error.external_id) {
              msg = 'User with this ECT id already exists.';
            } else if (err.error.error.email) {
              msg = 'User with this email already exists.';
            } else if (err.error.error.username) {
              msg = 'User with this username already exists.';
            } else if (err.error.error.agentId) {
              msg = 'User with this Agent ID already exists.';
            }
          } else {
            if (err && err.error.error.first_name) {
              msg = `Valid first name should be provided`;
            } else if (err && err.error.error.last_name) {
              msg = `Valid last name should be provided`;
            } else if (err && err.error.error.email) {
              msg = `Valid email should be provided`;
            } else if (err.error.error.agentId) {
              msg = 'Agent ID should be two digits or left blank.';
            } else {
              msg = `Cannot ${this.user ? 'modify' : 'create'} the user. Please try again.`;
            }
          }
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationService } from './validators/validators.service';
import { SharedComponents } from './shared-components';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { SharedPipes } from './shared-pipes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgentIdDirective } from './directives/agent-id.directive';

const sharedDirectives = [AgentIdDirective];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    ValidationService
  ],
  declarations: [
    ...SharedComponents,
    ...SharedPipes,
    ...sharedDirectives
  ],
  exports: [
    ...SharedComponents,
    ...SharedPipes,
    ...sharedDirectives,
    NgbModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class SharedModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}

import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CompaniesService } from '../../api/companies.service';

import 'rxjs/add/operator/finally';
import { Toast } from '../../shared/toast-message/toast';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import * as _ from 'lodash-es';
import { Features } from '../../types/company';

declare let $: any;

@Component({
  selector: 'up-features-dialog',
  templateUrl: './features-dialog.component.html',
  styleUrls: ['./features-dialog.component.scss']
})
export class FeaturesDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() companies?: any;

  @Output() submitFeature = new EventEmitter();

  public toast: Toast;
  public loading: boolean;
  public isFormSubmitted: boolean;
  public companyFeatures: Features = {};
  private features: Features = {};
  Object = Object;

  constructor(private companiesService: CompaniesService) {
    super();
  }


  ngOnInit() {
    this.companiesService.getFeaturesList()
      .subscribe(
        (res) => {
          this.features = res;
          this.companyFeatures = _.cloneDeep(this.features);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  ngAfterViewInit() {
    $(`#${this.id}`).on('hide.bs.modal', () => {
      this.toast = null;
    });
  }

  public createCompany(): void {
    const dataCompany = {
      features: this.companyFeatures,
    };
    const request = this.companiesService.updateAllCompanies(dataCompany);
    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (res) => {
          this.submitFeature.emit(res);
          $(`#${this.id}`).modal('hide');
        },
        (err) => {
          let msg;
          if (err && err.status === 409) {
            msg =  err.error.error;
          } else {
            msg = `Cannot modify companies features. Please try again.`;
          }
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });
  }
}

import { Directive, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

declare let $: any;

@Directive({
  selector: '[upTooltip]'
})
export class TooltipDirective implements OnInit {

  constructor(private element: ElementRef,
              private router: Router) {
    router.events
      .filter(event => event instanceof NavigationStart)
      .subscribe(() => {
        $(this.element.nativeElement).tooltip('hide');
      });
  }

  ngOnInit() {
    const jEl = $(this.element.nativeElement);
    const skin = jEl.data('skin') ? 'm-tooltip--skin-' + jEl.data('skin') : 'm-tooltip--skin-dark';
    const position = jEl.data('placement') || 'top';
    jEl.tooltip({
      trigger: 'hover',
      position: position,
      template: '<div class="m-tooltip ' + skin + ' tooltip" role="tooltip">\
                <div class="arrow"></div>\
                <div class="tooltip-inner"></div>\
            </div>'
    });
  }


}

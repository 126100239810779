import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from '../../shared/base-component/base-component.component';
import { User } from '../../types/user';
import { Toast } from '../../shared/toast-message/toast';
import { ValidationService } from '../../shared/validators/validators.service';
import { CallReviewersService } from '../../api/call-reviewers.service';

@Component({
  selector: 'up-call-reviewer-report-dialog',
  templateUrl: './call-reviewer-report-dialog.component.html',
  styleUrls: ['./call-reviewer-report-dialog.component.scss']
})
export class CallReviewerReportDialogComponent extends BaseComponent implements OnInit {
  @Input() user?: User;

  public toast: Toast;
  public loading: boolean;
  public reviewerForm: FormGroup;
  public isFormSubmitted: boolean;
  public startDate: any;
  public endDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    public activeModal: NgbActiveModal,
    private callReviewersService: CallReviewersService
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.setForm();
  }

  public submitReviewerForm(): void {
    if (!this.reviewerForm.valid) {
      this.isFormSubmitted = true;
      this.validationService.markAllControlsAsTouched(this.reviewerForm);
      return;
    }
    this.loading = true;
    const request = this.callReviewersService.downloadReport(this.reviewerForm.value);

    this.subs = request
      .finally(() => this.loading = false)
      .subscribe(
        (result) => {
          this.activeModal.close(result);
        },
        (err) => {
          let msg;
          if (err) {
            msg = `Cannot download the report. Please try again.`;
          }
          this.toast = {
            message: msg,
            type: 'danger'
          };
        });

  }

  private createForm() {
    this.reviewerForm = this.formBuilder.group({
      start_date: ['', [
        Validators.required
      ]],
      end_date: ['', [
        Validators.required
      ]]
    });
  }

  private setForm() {
    this.reviewerForm.patchValue(this.user || {});
  }
}

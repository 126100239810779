import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './companies.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyDialogComponent } from './company-dialog/company-dialog.component';
import { SiteCodeDialogComponent } from './site-code-dialog/site-code-dialog.component';
import { UsersModule } from '../users/users.module';
import { UsersComponent } from '../users/users.component';
import { FeaturesDialogComponent } from './features-dialog/features-dialog.component';
const routes: Routes = [
  {
    path: '',
    children: [{
      path: '',
      data: {
        title: 'Companies'
      },
      component: CompaniesComponent
    }, {
      path: 'companies/:companyId',
      component: UsersComponent,
      data: {title: 'test'}
    }]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    UsersModule
  ],
  declarations: [ CompaniesComponent, CompanyDialogComponent, SiteCodeDialogComponent, FeaturesDialogComponent ]
})
export class CompaniesModule { }

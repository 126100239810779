import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../validators/validators.service';

@Component({
  selector: 'up-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent implements OnInit {
  @Input() control: FormControl;
  @Input() messages: any = {};

  ngOnInit() {
  }

  get errors(): string[] {
    const control = this.control;
    if (control && control.touched && control.errors) {
      return Object.keys(control.errors)
        .filter((error) => control.errors[error])
        .map((error) => {
          return ValidationService.getValidatorErrorMessage(error, this.messages);
        });
    } else {
      return null;
    }
  }

}

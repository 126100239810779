import { Component, Input } from '@angular/core';
import { RateHistoryItem } from '../../types/reward';

@Component({
  selector: 'up-rate-history-modal',
  templateUrl: './rate-history-members-modal.component.html',
  styleUrls: ['./rate-history-members-modal.component.scss']
})
export class RateHistoryMembersModalComponent {
  @Input() id: string;
  @Input() data: RateHistoryItem | null;
}
